import produce from "immer";
import IAccount from "../models/IAccount";
import ICountry from "../models/ICountry";
import MspType from "../models/MspType";
import { dynamicSort, isStringNullOrEmpty } from "../utility";
import { IAccountFilters } from "../models/IAccountFilters";
import IDisplayState from "../models/IDisplayState";

export function addNewAccountToState(mspAccounts: IAccount[], newAccountId: number, newAccount: IAccount, closestParentId: number) {
  const account: IAccount = {
    ...newAccount,
    id: newAccountId,
    closestParentId: closestParentId,
  };
  if (newAccount.type === MspType.Subpartner) {
    return addNewSubpartnerToState(mspAccounts, account);
  } else {
    return addNewCustomerToState(mspAccounts, account);
  }
}

export function editAccountFromState(mspAccounts: IAccount[], itemsToDisplay: IAccount[], accountToUpdate: IAccount, updatedAccount: IAccount) {
  if (accountToUpdate.type === MspType.Partner || accountToUpdate.type === MspType.Subpartner) {
    return editMspFromState(mspAccounts, itemsToDisplay, accountToUpdate.id, updatedAccount);
  } else if (accountToUpdate.type === MspType.BillingAggregator) {
    return editBaFromState(mspAccounts, itemsToDisplay, accountToUpdate.id, updatedAccount);
  } else {
    return editCustomerFromState(mspAccounts, accountToUpdate.id, accountToUpdate.closestParentId, updatedAccount);
  }
}

export function editAccountFromStateWhenFiltersAreOn(mspAccounts: IAccount[], itemsToDisplay: IAccount[], accountToUpdate: IAccount, updatedAccount: IAccount, filters: IAccountFilters) {
  if (accountToUpdate.type === MspType.Customer) {
    const accIndex = itemsToDisplay.findIndex(x => x.id === accountToUpdate.id);
    if (accIndex > -1) {
      let orderedAccounts: IAccount[] = [];
      if (nameMatchesFilters(filters, updatedAccount)) {
        let nextState = produce(itemsToDisplay, draft => {
          draft[accIndex].name = accountToUpdate.name;
        });
        orderedAccounts = nextState.map(x => ({
          id: x.id,
          name: x.name,
          type: x.type,
          closestParentId: x.closestParentId,
          addresses: { isContactBillingSame: false },
          accounts: [],
          userAdminFlag: false,
          userBillFlag: false,
          canCreateSubPartners: false,
        }));
        orderedAccounts.sort(dynamicSort("name"));
      } else {
        orderedAccounts = produce(itemsToDisplay, draft => {
          draft.splice(accIndex, 1);
        });
      }
      const result = editAccountFromState(mspAccounts, itemsToDisplay, accountToUpdate, updatedAccount);
      if (result) {
        return { itemsToDisplay: orderedAccounts, mspAccounts: result.mspAccounts };
      }
      return { itemsToDisplay: orderedAccounts, mspAccounts: undefined };
    }
  }
}

export function editAccountFromStateSavedBeforeFiltering(savedState: IDisplayState, account: IAccount) {
  if (account.type === MspType.Customer) {
    const accIndex = savedState.itemsToDisplay.findIndex(x => x.id === account.id);
    const itemsToDisplay = savedState.itemsToDisplay;
    if (accIndex > -1) {
      let nextState = produce(itemsToDisplay, draft => {
        draft[accIndex].name = account.name;
      });
      let orderedAccounts = nextState.map(x => x);
      let hasParentInView: undefined | IAccount = undefined;
      if (nextState[0]?.type === MspType.Partner || nextState[0]?.type === MspType.Subpartner) {
        orderedAccounts = orderedAccounts.filter(x => x.type === MspType.Customer);
        hasParentInView = nextState[0];
      }
      orderedAccounts.sort(dynamicSort("name"));
      const nextSavedState = produce(savedState, draft => {
        if (hasParentInView !== undefined) {
          draft.itemsToDisplay = [hasParentInView, ...orderedAccounts];
        } else {
          draft.itemsToDisplay = orderedAccounts;
        }
      });
      return nextSavedState;
    }
  }
}

export function deleteAccountFromState(mspAccounts: IAccount[], account: IAccount) {
  if (account.type === MspType.Subpartner) {
    return deleteSubpartnerFromState(mspAccounts, account);
  } else {
    return deleteCustomerFromState(mspAccounts, account);
  }
}

export function deleteAccountFromStateWhenFiltersAreOn(mspAccounts: IAccount[], account: IAccount, itemsToDisplay: IAccount[]) {
  if (account.type === MspType.Customer) {
    const accIndex = itemsToDisplay.findIndex(x => x.id === account.id);
    if (accIndex > -1) {
      const nextState = produce(itemsToDisplay, draft => {
        draft.splice(accIndex, 1);
      });
      const result = deleteCustomerFromState(mspAccounts, account);
      if (result) {
        return { itemsToDisplay: nextState, mspAccounts: result.mspAccounts };
      }
      return { itemsToDisplay: nextState, mspAccounts: undefined };
    }
  }
}

export function deleteAccountFromStateSavedBeforeFiltering(savedState: IDisplayState, account: IAccount) {
  if (account.type === MspType.Customer) {
    const accIndex = savedState.itemsToDisplay.findIndex(x => x.id === account.id);
    const itemsToDisplay = savedState.itemsToDisplay;
    if (accIndex > -1) {
      const nextState = produce(itemsToDisplay, draft => {
        draft.splice(accIndex, 1);
      });
      const nextSavedState = produce(savedState, draft => {
        draft.itemsToDisplay = nextState;
      });
      return nextSavedState;
    }
  }
}

export function isM365Athorized(response: any): boolean {
  return response && response.data && response.data[0] && response.data[0].status === "Authorized";
}

export function getM365AuthUpdateTime(response: any): string {
  if (response && response.data && response.data[0] && response.data[0].updatedTime) {
    let updatedTime = new Date(response.data[0].updatedTime);
    if (updatedTime) {
      return updatedTime.toLocaleDateString();
    }
  }
  return "";
}

export function updateAccountM365AuthFromState(account: IAccount, m365auth: any): IAccount {
  let m365authStatus = 0;
  let m365authUpdatedTime = "";
  if (isM365Athorized(m365auth)) {
    m365authStatus = 1;
    m365authUpdatedTime = getM365AuthUpdateTime(m365auth);
  }
  return { ...account, m365AuthLinked: m365authStatus, m365AuthUpdateTime: m365authUpdatedTime };
}

export function updateAccountListWithM365Status(accounts: IAccount[], account: IAccount, m365auth: any) {
  const index = accounts.findIndex((x: IAccount) => x.id === account.id);
  if (index > -1) {
    const nextState = produce(accounts, draft => {
      const updated = updateAccountM365AuthFromState(account, m365auth);
      draft[index].m365AuthLinked = updated.m365AuthLinked;
      draft[index].m365AuthUpdateTime = updated.m365AuthUpdateTime;
    });
    return nextState;
  }
  return accounts;
}

export function updateCountriesWithStates(countries: ICountry[], states: any, countryCode: string) {
  const countryIndex = countries.findIndex(c => c.country === countryCode);
  if (countryIndex < 0) {
    throw new Error(`No country with code ${countryCode}`);
  }
  return produce(countries, draft => {
    draft[countryIndex].states = states;
  });
}

export function setAccountsPageNumberForAccountId(itemsToDisplay: any[], accountsPageSize: number, accountId: number): number {
  const index = itemsToDisplay.findIndex(value => value.id === accountId);
  if (index > -1) {
    return Math.floor(index / accountsPageSize) + 1;
  } else {
    throw new Error(`No account with id ${accountId}`);
  }
}

export function getMspAccountsToDisplay(mspAccounts: IAccount[]) {
  if (mspAccounts.length > 1) {
    let subpartners = mspAccounts.filter(x => x.type === MspType.Subpartner);
    const partner = mspAccounts.filter(x => x.type === MspType.Partner);
    subpartners.sort(dynamicSort("name"));
    return [...partner, ...subpartners];
  } else {
    return getDisplayItemsForMsp(mspAccounts, mspAccounts[0]);
  }
}

export function getDisplayItemsForMsp(mspAccounts: IAccount[], mspAccount: IAccount) {
  const mspAccountIndex = mspAccounts.findIndex(value => value.id === mspAccount.id);
  if (mspAccountIndex < 0) {
    throw new Error(`No account with id ${mspAccount.id}`);
  }

  const orderedCustomers = sortCustomersForMsp(mspAccounts[mspAccountIndex]);
  return [mspAccounts[mspAccountIndex], ...orderedCustomers];
}

export function goToAccount(items: IAccount[], accountsPageSize: number, account: IAccount, isBaLoggedIn?: boolean) {
  const searchedId = account.type === MspType.Subpartner || account.type === MspType.Partner || account.type === MspType.BillingAggregator ? account.id : account.closestParentId;
  const index = items.findIndex(value => value.id === searchedId);
  if (index < 0) {
    throw new Error(`No parent for account with id ${account.id}`);
  }
  const newExpandedPartner = items[index];
  let updatedItemsToDisplay: IAccount[] = [];
  let pageNumber: number = 1;
  if (isBaLoggedIn) {
    updatedItemsToDisplay = items;
    pageNumber = setAccountsPageNumberForAccountId(items, accountsPageSize, account.id);
  } else {
    const newlyexpandedAccountAccessMspAccounts = sortCustomersForMsp(newExpandedPartner);
    updatedItemsToDisplay = [newExpandedPartner, ...newlyexpandedAccountAccessMspAccounts];
    pageNumber = setAccountsPageNumberForAccountId(updatedItemsToDisplay, accountsPageSize, account.id);
  }

  return { itemsToDisplay: updatedItemsToDisplay, accountsPageNumber: pageNumber, newExpandedPartner, selectedAcc: account };
}

export function computeJsonAccountWithAddress(account: IAccount, notes?: string) {
  let newAccount: any = {
    id: account.id,
    name: account.name,
    type: account.type,
    accounts: account.accounts,
    addresses: {
      isContactBillingSame: account.addresses?.isContactBillingSame,
    },
    ...(notes && { notes: notes }),
  };
  if (account.addresses?.contact) {
    newAccount = produce(newAccount, (draft: any) => {
      draft.addresses.contact = {
        name: account.addresses?.contact?.name,
        company: account.addresses?.contact?.company,
        street: account.addresses?.contact?.street,
        city: account.addresses?.contact?.city,
        country: account.addresses?.contact?.country,
        state: account.addresses?.contact?.state,
        zip: account.addresses?.contact?.zip,
        phone: account.addresses?.contact?.phone,
        email: account.addresses?.contact?.email,
      };
    });
  }

  if (!account.addresses?.isContactBillingSame && account.addresses?.billing) {
    newAccount = produce(newAccount, (draft: any) => {
      draft.addresses.billing = {
        name: account.addresses?.billing?.name,
        street: account.addresses?.billing?.street,
        city: account.addresses?.billing?.city,
        country: account.addresses?.billing?.country,
        state: account.addresses?.billing?.state,
        zip: account.addresses?.billing?.zip,
        phone: account.addresses?.billing?.phone,
        email: account.addresses?.billing?.email,
      };
    });
  }

  return produce(newAccount, (draft: any) => {
    if (!account.type.includes(MspType.BillingAggregator)) {
      if (!isStringNullOrEmpty(account.addresses?.contact?.street2)) {
        draft.addresses.contact.street2 = account.addresses?.contact?.street2;
      }
      if (!isStringNullOrEmpty(account.addresses?.contact?.street3)) {
        draft.addresses.contact.street3 = account.addresses?.contact?.street3;
      }
      if (!isStringNullOrEmpty(account.addresses?.contact?.street4)) {
        draft.addresses.contact.street4 = account.addresses?.contact?.street4;
      }
    }
    if (!account.addresses?.isContactBillingSame && account.addresses?.billing) {
      if (!isStringNullOrEmpty(account.addresses.billing.street2)) {
        draft.addresses.billing.street2 = account.addresses.billing.street2;
      }
      if (!isStringNullOrEmpty(account.addresses.billing.street3)) {
        draft.addresses.billing.street3 = account.addresses.billing.street3;
      }
      if (!isStringNullOrEmpty(account.addresses.billing.street4)) {
        draft.addresses.billing.street4 = account.addresses.billing.street4;
      }
    }
  });
}

export function getUpdatedAccount(account: IAccount, accountUpdated: IAccount, addressResult: any) {
  let updatedAccount = {
    ...account,
    name: accountUpdated.name,
    addresses: {
      ...account.addresses,
      isContactBillingSame: addressResult.isContactBillingSame,
      contact: addressResult.contact,
    },
  };
  if (!addressResult.isContactBillingSame) {
    updatedAccount = produce(updatedAccount, (draft: any) => {
      draft.addresses.billing = addressResult.billing;
    });
  }
  return updatedAccount;
}

export function computeLoggedInMsp(result: any) {
  const resultItems = result.accounts.map((item: IAccount) => ({ ...item, closestParentId: result.id }));
  if (result.type === MspType.BillingAggregator) {
    const partners = resultItems.filter((x: IAccount) => x.type === MspType.Partner);
    const orderedPartners = partners.sort(dynamicSort("name"));
    const newMspAccountLoggedIn = {
      ...result,
      userBillFlag: false,
      userAdminFlag: false,
      canCreateSubPartners: false,
      accounts: orderedPartners,
      noOfAccounts: 0,
    };
    return { newMspAccountLoggedIn, subpartners: [] };
  } else {
    const mspCustomers = resultItems.filter((x: IAccount) => x.type === MspType.Customer);
    const orderedCustomers = mspCustomers.sort(dynamicSort("name"));
    const newMspAccountLoggedIn = {
      ...result,
      userBillFlag: result.userBillFlag === 1 ? true : false,
      userAdminFlag: result.userAdminFlag === 1 ? true : false,
      canCreateSubPartners: result.canCreateSubPartners === 1 ? true : false,
      accounts: orderedCustomers,
    };
    const subpartners = resultItems.filter((x: IAccount) => x.type === MspType.Subpartner);
    const orderedSubpartners = subpartners.sort(dynamicSort("name"));
    return { newMspAccountLoggedIn, subpartners: orderedSubpartners };
  }
}

export function computeSubpartnersWithAccounts(subpartners: any, allAccountsWithChildren: any) {
  return subpartners.map((subpartner: IAccount) => {
    const customerAccounts = allAccountsWithChildren[subpartner.id]?.accounts.filter((account: IAccount) => account.type === MspType.Customer);
    let accountsWithPartnerId = [];
    if (customerAccounts) {
      accountsWithPartnerId = customerAccounts?.map((a: IAccount) => ({ ...a, closestParentId: subpartner.id }));
    }
    return { ...subpartner, accounts: accountsWithPartnerId };
  });
}

function addNewSubpartnerToState(mspAccounts: IAccount[], newAccount: IAccount) {
  const subpartners = mspAccounts.filter(x => x.type === MspType.Subpartner);
  const partner = mspAccounts.filter(x => x.type === MspType.Partner);
  const orderedSubpartners = [...subpartners, newAccount].sort(dynamicSort("name"));
  const updatedMspAccounts = [...partner, ...orderedSubpartners];
  return { itemsToDisplay: updatedMspAccounts, mspAccounts: updatedMspAccounts };
}

function addNewCustomerToState(mspAccounts: IAccount[], newAccount: IAccount) {
  const mspAccountIndex = mspAccounts.findIndex(value => value.id === newAccount.closestParentId);
  if (mspAccountIndex > -1) {
    const existingAccounts = mspAccounts[mspAccountIndex].accounts;
    let updatedAccounts = existingAccounts ? [...existingAccounts, newAccount] : [newAccount];
    updatedAccounts.sort(dynamicSort("name"));
    const nextStateAccounts: IAccount[] = produce(mspAccounts, (draft: IAccount[]) => {
      draft[mspAccountIndex].accounts = updatedAccounts;
    });
    const itemsToDisplay = [nextStateAccounts[mspAccountIndex], ...updatedAccounts];
    return { itemsToDisplay: itemsToDisplay, mspAccounts: nextStateAccounts };
  } else {
    throw new Error(`No subpartner with id ${newAccount.closestParentId}`);
  }
}

export function sortCustomersForMsp(mspAccount: IAccount | undefined) {
  if (mspAccount?.accounts) {
    const filteredAccounts = mspAccount.accounts.filter(x => x.type === MspType.Customer);
    let orderedAccounts = filteredAccounts.map(x => ({
      id: x.id,
      name: x.name,
      type: x.type,
      closestParentId: x.closestParentId,
    }));
    orderedAccounts.sort(dynamicSort("name"));
    return orderedAccounts as IAccount[];
  } else {
    return [] as IAccount[];
  }
}

function editBaFromState(mspAccounts: IAccount[], itemsToDisplay: IAccount[], accountIdToUpdate: number, updatedAccount: IAccount) {
  const displayIndex = itemsToDisplay.findIndex(value => value.id === accountIdToUpdate);
  if (displayIndex < 0) {
    throw new Error(`No Billing Aggregator with id in displayed items ${accountIdToUpdate}`);
  }
  const updatedItemsToDisplay = produce(itemsToDisplay, (draft: IAccount[]) => {
    draft[displayIndex] = updatedAccount;
  });
  return { itemsToDisplay: updatedItemsToDisplay, mspAccounts: mspAccounts };
}

function editMspFromState(mspAccounts: IAccount[], itemsToDisplay: IAccount[], accountIdToUpdate: number, updatedAccount: IAccount) {
  const mspAccountIndex = mspAccounts.findIndex(value => value.id === accountIdToUpdate);
  if (mspAccountIndex < 0) {
    throw new Error(`No subpartner with id ${accountIdToUpdate}`);
  }
  const updatedMspAccountsList: IAccount[] = produce(mspAccounts, (draft: IAccount[]) => {
    draft[mspAccountIndex] = updatedAccount;
    draft[mspAccountIndex].accounts = mspAccounts[mspAccountIndex].accounts;
  });

  let subpartners = updatedMspAccountsList.filter(x => x.type === MspType.Subpartner);
  const partner = updatedMspAccountsList.filter(x => x.type === MspType.Partner);
  subpartners.sort(dynamicSort("name"));
  const updatedMspAccounts = [...partner, ...subpartners];

  const displayIndex = itemsToDisplay.findIndex(value => value.id === accountIdToUpdate);
  if (displayIndex < 0) {
    throw new Error(`No subpartner with id in displayed items ${accountIdToUpdate}`);
  }
  const updatedItemsToDisplay = produce(itemsToDisplay, (draft: IAccount[]) => {
    draft[displayIndex] = updatedAccount;
  });

  return { itemsToDisplay: updatedItemsToDisplay, mspAccounts: updatedMspAccounts };
}

function editCustomerFromState(mspAccounts: IAccount[], accountToUpdateId: number, accountToUpdateclosestParentId: number, updatedAccount: IAccount) {
  const mspAccountIndex = mspAccounts.findIndex(value => value.id === accountToUpdateclosestParentId);
  if (mspAccountIndex > -1) {
    const customers = mspAccounts[mspAccountIndex].accounts;
    if (customers) {
      const customerIndex = customers.findIndex(value => value.id === accountToUpdateId);
      if (customerIndex > -1) {
        const nextStateCustomers: IAccount[] = produce(customers, (draft: IAccount[]) => {
          draft[customerIndex] = updatedAccount;
        });
        const nextStateMspAccounts: IAccount[] = produce(mspAccounts, (draft: IAccount[]) => {
          draft[mspAccountIndex].accounts = nextStateCustomers;
        });
        const orderedCustomers = sortCustomersForMsp(nextStateMspAccounts[mspAccountIndex]);
        const itemsToDisplay = [mspAccounts[mspAccountIndex], ...orderedCustomers];
        return { itemsToDisplay: itemsToDisplay, mspAccounts: nextStateMspAccounts };
      }
    }
  }
  return undefined;
}

function deleteSubpartnerFromState(mspAccounts: IAccount[], account: IAccount) {
  let wasLastSubpartner: boolean = false;

  const mspAccountIndex = mspAccounts.findIndex(value => value.id === account.id);
  if (mspAccountIndex < 0) {
    throw new Error(`No subpartner with id ${account.id}`);
  }
  const nextStateMspAccounts: IAccount[] = produce(mspAccounts, (draft: IAccount[]) => {
    draft.splice(mspAccountIndex, 1);
  });

  const subpartners = nextStateMspAccounts.filter(x => x.type === MspType.Subpartner);
  const partner = nextStateMspAccounts.filter(x => x.type === MspType.Partner);
  let updatedMspAccounts = [];
  if (subpartners.length > 0) {
    updatedMspAccounts = [...partner, ...subpartners];
  } else {
    const children = partner[0].accounts !== undefined ? [...partner[0].accounts] : [];
    updatedMspAccounts = [...partner, ...children];
    wasLastSubpartner = true;
  }
  return { itemsToDisplay: updatedMspAccounts, mspAccounts: nextStateMspAccounts, wasLastSubpartner: wasLastSubpartner };
}

function deleteCustomerFromState(mspAccounts: IAccount[], account: IAccount) {
  const mspAccountIndex = mspAccounts.findIndex(value => value.id === account.closestParentId);
  if (mspAccountIndex > -1) {
    const customers = mspAccounts[mspAccountIndex].accounts;
    if (customers) {
      const customerIndex = customers.findIndex(value => value.id === account.id);
      if (customerIndex > -1) {
        const nextStateCustomers: IAccount[] = produce(customers, (draft: IAccount[]) => {
          draft.splice(customerIndex, 1);
        });
        const nextStateMspAccounts: IAccount[] = produce(mspAccounts, (draft: IAccount[]) => {
          draft[mspAccountIndex].accounts = nextStateCustomers;
        });
        const orderedCustomers = sortCustomersForMsp(nextStateMspAccounts[mspAccountIndex]);
        const itemsToDisplay = [nextStateMspAccounts[mspAccountIndex], ...orderedCustomers];
        return { itemsToDisplay: itemsToDisplay, mspAccounts: nextStateMspAccounts, wasLastSubpartner: false };
      }
    }
  }
  return undefined;
}

export function nameMatchesFilters(filters: IAccountFilters | undefined, updatedAccount: IAccount): boolean {
  if (filters === undefined || filters.name === undefined || filters.name.length === 0 || (filters.name.length > 0 && updatedAccount.name.toLocaleLowerCase().includes(filters.name.toLocaleLowerCase()))) {
    return true;
  }
  return false;
}
