import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../models/IAccount";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { dynamicSort, getAdjustedPageNumberAfterDelete } from "../utility";
import { GeneralActionTypes, updateMspAccountsWithChildren } from "./generalActions";
import ICountry from "../models/ICountry";
import { CancelTokenSource } from "axios";
import IAccountAddress from "../models/IAccountAddress";
import { isUK, searchAccount, filterSmbByName, filterResultsByType, addUpdateAccountToAccountNamesList, removeAccountFromAccountNamesList, computeItemsToFilter, getFilterMethod, getNamesForFilteredAccounts, mapParentAccountNameToFilterResults } from "../Utilities/accountsHelper";
import IDisplayState from "../models/IDisplayState";
import { handleError } from "./actionsErrorHandler";
import { addNewAccountToState, deleteAccountFromState, editAccountFromState, editAccountFromStateWhenFiltersAreOn, getMspAccountsToDisplay, goToAccount, setAccountsPageNumberForAccountId, updateCountriesWithStates, updateAccountM365AuthFromState, updateAccountListWithM365Status, deleteAccountFromStateWhenFiltersAreOn, deleteAccountFromStateSavedBeforeFiltering, editAccountFromStateSavedBeforeFiltering } from "../businessLogic/accounts";
import MspType from "../models/MspType";
import { cancelFilterAccountsActionTokenAndCreateNew, cancelCurrentGetAddressActionTokenAndCreateNew, cancelGeneralActionTokenAndCreateNew, cancelLoadPartnerAllChildrenNamesActionTokenAndCreateNew, cancelCurrent, cancelFilterAccountsActionToken, cancelLoadAccountInfoActionTokenAndCreateNew, cancelLoadEchoUsernameActionTokenAndCreateNew } from "./cancelAction";
import { IAccountFilters } from "../models/IAccountFilters";
import { ActionTypes, getAddAccountItemActionType, getDeleteAccountItemActionType, getEditAccountItemActionType } from "./ActionTypes";
import { LocalStoragePreferences, localStorageService } from "../service/localStorageService";
import { ProductActionTypes } from "./productActions";
import { IDeleteAccountSerial } from "../components/Accounts/DeleteAccount/DeleteAccountSerialsTable";
import { removeAccountSerialsFromState } from "../businessLogic/products";
import { cancelLoadSubpartnerCustomersDispatchAction, getCustomersDispatchAction, goBackToViewMspAccountsDispatchAction, loadParentAccountWithChildrenDispatchAction, navigateToAccountDispatchAction, onSelectAccountItemDispatchAction, prepareAccountsListDispatchAction, setDisplayCustomersByAccountIdDispatchAction } from "./actionsHelpers/accountActionsHelper";
import produce from "immer";

export enum AccountActionTypes {
  ADD_ACCOUNT = "ADD_ACCOUNT",
  SET_LOADING_ACCOUNT_INFO = "SET_LOADING_ACCOUNT_INFO",
  SET_CANCEL_LOAD_SUBPARTNER_CUSTOMERS_TOKEN = "SET_CANCEL_LOAD_SUBPARTNER_CUSTOMERS_TOKEN",
  SET_CANCEL_LOAD_CUSTOMER_PARENT_AND_ITS_CHILDREN_TOKEN = "SET_CANCEL_LOAD_CUSTOMER_PARENT_AND_ITS_CHILDREN_TOKEN",
  LOAD_PARTNERS_CHILDREN = "LOAD_PARTNERS_CHILDREN",
  LOAD_SUBPARTNERS_CHILDREN = "LOAD_SUBPARTNERS_CHILDREN",
  LOAD_CUSTOMERS = "LOAD_CUSTOMERS",
  LOAD_ADDRESS = "LOAD_ADDRESS",
  LOAD_COUNTRIES = "LOAD_COUNTRIES",
  LOAD_STATES = "LOAD_STATES",
  SET_DISPLAY_ITEMS = "SET_DISPLAY_ITEMS",
  SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT",
  SET_LOADED_ACCOUNT = "SET_LOADED_ACCOUNT",
  SET_EXPANDED_PARTNER = "SET_EXPANDED_PARTNER",
  LOAD_ACCOUNT_INFO = "LOAD_ACCOUNT_INFO",
  SET_MSP_ACCOUNTS = "SET_MSP_ACCOUNTS",
  SET_PAGE_SIZE = "SET_PAGE_SIZE",
  SET_PAGE_NUMBER = "SET_PAGE_NUMBER",
  EDIT_ACCOUNT = "EDIT_ACCOUNT",
  DELETE_ACCOUNT = "DELETE_ACCOUNT",
  SET_CANCEL_ADDRESS_TOKEN = "SET_CANCEL_ADDRESS_TOKEN",
  CANCEL_LOAD_ADDRESS = "CANCEL_LOAD_ADDRESS",
  GET_ALL_CHILDREN_ACCOUNT_NAMES = "GET_ALL_CHILDREN_ACCOUNT_NAMES",
  SAVE_DISPLAY_STATE = "SAVE_DISPLAY_STATE",
  GO_TO_ACCOUNT_ACTION = "GO_TO_ACCOUNT_ACTION",
  SET_PREV_SELECTED_ACCOUNT = "SET_PREV_SELECTED_ACCOUNT",
  SET_STATES = "SET_STATES",
  FETCH_M365_AUTH = "FETCH_M365_AUTH",
  FETCH_M365_AUTH_FOR_ACC = "FETCH_M365_AUTH_FOR_ACC",
  SET_M365_AUTH = "SET_M365_AUTH",
  REVOKE_M365_AUTH = "REVOKE_M365_AUTH",
  FETCH_M365_DOMAIN_LIST = "FETCH_M365_DOMAIN_LIST",
  SET_LAST_USAGE_SYNC_TIME = "SET_LAST_USAGE_SYNC_TIME",
  SET_SELECTED_ACCOUNT_TO_FILTER_PRODUCTS_FOR_BA = "SET_SELECTED_ACCOUNT_TO_FILTER_PRODUCTS_FOR_BA",
  SET_CANCEL_LOAD_CHILDREN_TOKEN = "SET_CANCEL_LOAD_CHILDREN_TOKEN",
  SET_CANCEL_LOAD_ALL_CHILDREN_NAMES_TOKEN = "SET_CANCEL_LOAD_ALL_CHILDREN_NAMES_TOKEN",
  SET_LOADING_ACCOUNT_M365_AUTH = "SET_LOADING_ACCOUNT_M365_AUTH",
  FETCH_PRODUCT_LICENSES = "FETCH_PRODUCT_LICENSES",
  SET_SHOW_EMPTY_PANEL = "SET_SHOW_EMPTY_PANEL",
  SET_ACCOUNT_FILTERS = "SET_ACCOUNT_FILTERS",
  SET_FITLER_CHILDREN_OF_ACCOUNT_ID = "SET_FITLER_CHILDREN_OF_ACCOUNT_ID",
  SET_CANCEL_FILTER_ACCOUNTS_TOKEN = "SET_CANCEL_FILTER_ACCOUNTS_TOKEN",
  SET_LOADING_FILTERED_ACCOUNTS = "SET_LOADING_FILTERED_ACCOUNTS",
  LOAD_ACCOUNT_ECHO_USERNAME = "LOAD_ACCOUNT_ECHO_USERNAME",
  GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO",
  SET_CANCEL_LOAD_ACCOUNT_INFO_TOKEN = "SET_CANCEL_LOAD_ACCOUNT_INFO_TOKEN",
  SET_CANCEL_LOAD_ECHOUSERNAME_TOKEN = "SET_CANCEL_LOAD_ECHOUSERNAME_TOKEN",
}

export interface IAddAccountAction {
  type: AccountActionTypes.ADD_ACCOUNT;
  mspAccounts: IAccount[];
  itemsToDisplay: IAccount[];
}

export interface IEditAccountAction {
  type: AccountActionTypes.EDIT_ACCOUNT;
  mspAccounts: IAccount[];
  itemsToDisplay: IAccount[];
}

export interface IGetAccountExtraInfoAction {
  type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO;
  loadingAccountId: number | undefined;
}

export interface ISetCancelLoadSubpartnerCustomersTokenAction {
  type: AccountActionTypes.SET_CANCEL_LOAD_SUBPARTNER_CUSTOMERS_TOKEN;
  loadSubpartnerCustomersCancellationTokenSource: CancelTokenSource | undefined;
}

export interface ISetCancelLoadCustomerParentAndItsChildrenTokenAction {
  type: AccountActionTypes.SET_CANCEL_LOAD_CUSTOMER_PARENT_AND_ITS_CHILDREN_TOKEN;
  loadCustomerParentAndItsChildrenCancellationTokenSource: CancelTokenSource;
}

export interface IGetCountriesAction {
  type: AccountActionTypes.LOAD_COUNTRIES;
  countries: ICountry[];
}

export interface ISetStatesAction {
  type: AccountActionTypes.SET_STATES;
  countries: ICountry[];
}

export interface IGetStatesAction {
  type: AccountActionTypes.LOAD_STATES;
  countries: ICountry[];
}

export interface IGetAccountAddressAction {
  type: AccountActionTypes.LOAD_ADDRESS;
  loadingAddress: boolean;
  addressToDisplay: IAccountAddress | undefined;
}

export interface ISetDisplayItemsAction {
  type: AccountActionTypes.SET_DISPLAY_ITEMS;
  itemsToDisplay: IAccount[];
}

export interface ISetAccountsPageSizeAction {
  type: AccountActionTypes.SET_PAGE_SIZE;
  accountsPageSize: number;
}

export interface ISetAccountsPageNumberAction {
  type: AccountActionTypes.SET_PAGE_NUMBER;
  accountsPageNumber: number;
}

export interface ISetSelectedAccountAction {
  type: AccountActionTypes.SET_SELECTED_ACCOUNT;
  selectedAccount: IAccount | undefined;
  showEmptyPanel: boolean;
}

export interface ISetLoadedAccountAction {
  type: AccountActionTypes.SET_LOADED_ACCOUNT;
  loadedAccount: IAccount | undefined;
}

export interface ISetExpandedPartnerAction {
  type: AccountActionTypes.SET_EXPANDED_PARTNER;
  expandedPartner: IAccount | undefined;
}

export interface ISetMspAccounts {
  type: AccountActionTypes.SET_MSP_ACCOUNTS;
  itemsToDisplay: IAccount[];
  mspAccounts: IAccount[];
}

export interface ISetEditAccountAction {
  type: AccountActionTypes.EDIT_ACCOUNT;
  mspAccounts: IAccount[];
  itemsToDisplay: IAccount[];
}

export interface IDeleteAccountAction {
  type: AccountActionTypes.DELETE_ACCOUNT;
  mspAccounts: IAccount[];
  itemsToDisplay: IAccount[];
}

export interface ISetCancelAddressTokenAction {
  type: AccountActionTypes.SET_CANCEL_ADDRESS_TOKEN;
  loadAddressCancellationTokenSource: CancelTokenSource;
}

export interface ICancelLoadAddressAction {
  type: AccountActionTypes.CANCEL_LOAD_ADDRESS;
}

export interface IGetAllChildrenAccountNames {
  type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES;
  loadingAllChildrenAccountNames: boolean;
  accountsNames: IAccount[];
}

export interface ISaveStateBeforeFilterAccounts {
  type: AccountActionTypes.SAVE_DISPLAY_STATE;
  stateBeforeFilterAccounts: IDisplayState | undefined;
}

export interface IGoToAccountAction {
  type: AccountActionTypes.GO_TO_ACCOUNT_ACTION;
  selectedAccount: IAccount;
  itemsToDisplay: IAccount[];
  expandedPartner: IAccount;
  accountsPageNumber: number;
}

export interface ISetPrevSelectedAccount {
  type: AccountActionTypes.SET_PREV_SELECTED_ACCOUNT;
  prevSelectedAccount: IAccount;
}

export interface ISetM365Auth {
  type: AccountActionTypes.SET_M365_AUTH;
}
export interface IRevokeM365Auth {
  type: AccountActionTypes.REVOKE_M365_AUTH;
}

export interface IFetchM365DomainList {
  type: AccountActionTypes.FETCH_M365_DOMAIN_LIST;
}

export interface IFetchM365Auth {
  type: AccountActionTypes.FETCH_M365_AUTH;
  selectedAccount: IAccount;
  loadingAccountM365Auth: boolean;
}

export interface ISetLoadingAccountM365Auth {
  type: AccountActionTypes.SET_LOADING_ACCOUNT_M365_AUTH;
  loadingAccountM365Auth: boolean;
}

export interface IFetchProductLicenses {
  type: AccountActionTypes.FETCH_PRODUCT_LICENSES;
}

export interface IFetchM365AuthForAcc {
  type: AccountActionTypes.FETCH_M365_AUTH_FOR_ACC;
  loadingM356StatusForAccountId: number | undefined;
  accountsNames: IAccount[];
}

export interface ISetLastUsageSyncTimeAction {
  type: AccountActionTypes.SET_LAST_USAGE_SYNC_TIME;
  lastUsageSyncTime: string;
}

export interface ISetSelectedAccountToFilterProductsForBA {
  type: AccountActionTypes.SET_SELECTED_ACCOUNT_TO_FILTER_PRODUCTS_FOR_BA;
  selectedAccountToFilterProductsForBA: IAccount | undefined;
}

export interface ISetCancelLoadChildrenToken {
  type: AccountActionTypes.SET_CANCEL_LOAD_CHILDREN_TOKEN;
  childrenCancellationTokenSource: CancelTokenSource;
}

export interface ISetCancelLoadAllChildrenNamesToken {
  type: AccountActionTypes.SET_CANCEL_LOAD_ALL_CHILDREN_NAMES_TOKEN;
  allchildrenNamesCancellationTokenSource: CancelTokenSource;
}

export interface ISetShowEmptyPanelAction {
  type: AccountActionTypes.SET_SHOW_EMPTY_PANEL;
  showEmptyPanel: boolean;
}

export interface ISetAccountFiltersAction {
  type: AccountActionTypes.SET_ACCOUNT_FILTERS;
  filters: IAccountFilters;
}

export interface ISetFilterChildrenOfAccountIdAction {
  type: AccountActionTypes.SET_FITLER_CHILDREN_OF_ACCOUNT_ID;
  filterChildrenOfAccountId?: number;
  directChildrenOnly?: boolean;
}

export interface ISetCancelFilterAccountsTokenAction {
  type: AccountActionTypes.SET_CANCEL_FILTER_ACCOUNTS_TOKEN;
  filterAccountsCancellationTokenSource: CancelTokenSource | undefined;
}

export interface ISetLoadingFilteredAccounts {
  type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS;
  loadingFilteredAccounts: boolean;
}

export interface ILoadAccountEchoUserName {
  type: AccountActionTypes.LOAD_ACCOUNT_ECHO_USERNAME;
  loadingEchoUserName: boolean;
  echoUserName: string;
}

export interface IGetAccountInfoStatus {
  type: AccountActionTypes.GET_ACCOUNT_INFO;
  loadingAccountInfo: boolean;
  hasIBU: boolean;
  notes?: string;
}

export interface ISetCancelLoadAccountInfoToken {
  type: AccountActionTypes.SET_CANCEL_LOAD_ACCOUNT_INFO_TOKEN;
  accountInfoCancellationTokenSource: CancelTokenSource;
}

export interface ISetCancelLoadEchoUsernameToken {
  type: AccountActionTypes.SET_CANCEL_LOAD_ECHOUSERNAME_TOKEN;
  echoUsernameCancellationTokenSource: CancelTokenSource;
}

export type AccountActions = IAddAccountAction | IEditAccountAction | IGetAccountExtraInfoAction | ISetCancelLoadSubpartnerCustomersTokenAction | ISetCancelLoadCustomerParentAndItsChildrenTokenAction | IGetAccountAddressAction | IGetCountriesAction | IGetStatesAction | ISetDisplayItemsAction | ISetAccountsPageSizeAction | ISetAccountsPageNumberAction | ISetSelectedAccountAction | ISetExpandedPartnerAction | ISetMspAccounts | ISetEditAccountAction | IDeleteAccountAction | ISetCancelAddressTokenAction | ICancelLoadAddressAction | ISaveStateBeforeFilterAccounts | IGoToAccountAction | ISetPrevSelectedAccount | ISetStatesAction | ISetM365Auth | IRevokeM365Auth | IFetchM365Auth | ISetLoadedAccountAction | ISetLastUsageSyncTimeAction | IGetAllChildrenAccountNames | ISetSelectedAccountToFilterProductsForBA | ISetCancelLoadChildrenToken | ISetCancelLoadAllChildrenNamesToken | IFetchM365DomainList | IFetchM365AuthForAcc | ISetLoadingAccountM365Auth | IFetchProductLicenses | ISetShowEmptyPanelAction | ISetAccountFiltersAction | ISetCancelFilterAccountsTokenAction | ISetLoadingFilteredAccounts | ILoadAccountEchoUserName | ISetFilterChildrenOfAccountIdAction | IGetAccountInfoStatus | ISetCancelLoadAccountInfoToken | ISetCancelLoadEchoUsernameToken;

export const fetchAccountAllChildren: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAllChildrenAccountNames>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const currentAccountNames = getState().accountState.accountsNames;
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
        loadingAllChildrenAccountNames: true,
        accountsNames: currentAccountNames,
      });

      const newCancelTokenSource = cancelLoadPartnerAllChildrenNamesActionTokenAndCreateNew(getState, dispatch);

      try {
        const allChildren = await mspService.fetchAccountAllChildren(apiUrl, account, newCancelTokenSource.token);
        dispatch({
          type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
          loadingAllChildrenAccountNames: false,
          accountsNames: allChildren.accounts,
        });
        return allChildren;
      } catch (error) {
        console.log(error);
      }
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
          loadingAllChildrenAccountNames: false,
          accountsNames: currentAccountNames,
        });
      });
      return currentAccountNames;
    }
  };
};

export const loadParentAccountWithChildrenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetMspAccounts>> = (accountId: number, withChildren: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    return await loadParentAccountWithChildrenDispatchAction(getState, dispatch, accountId, withChildren);
  };
};

export const addAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddAccountAction>> = (closestParentId: number, newAccount: IAccount, notes?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const result = await mspService.addAccount(apiUrl, closestParentId, newAccount, notes);
      const { mspAccounts } = getState().accountState;
      try {
        const nextState = addNewAccountToState(mspAccounts, result.id, newAccount, closestParentId);
        dispatch({
          type: AccountActionTypes.ADD_ACCOUNT,
          itemsToDisplay: nextState.itemsToDisplay,
          mspAccounts: nextState.mspAccounts,
        });

        const { accountsNames } = getState().accountState;
        const nextStateAccountsNames = addUpdateAccountToAccountNamesList(accountsNames, {
          id: result.id,
          name: newAccount.name,
          type: newAccount.type,
          closestParentId: closestParentId,
        });
        dispatch({
          type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
          loadingAllChildrenAccountNames: false,
          accountsNames: nextStateAccountsNames,
        });
      } catch (error) {
        console.log(error);
      }
      return result.id;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: AccountActionTypes.ADD_ACCOUNT,
            itemsToDisplay: getState().accountState.itemsToDisplay,
            mspAccounts: getState().accountState.mspAccounts,
          });
        },
        () => {},
        true,
        getAddAccountItemActionType(newAccount.type),
      );
      return -1;
    }
  };
};

export const editAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IEditAccountAction>> = (accountToUpdate: IAccount, notes?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const updatedAccount = await mspService.editAccount(apiUrl, accountToUpdate);
      if (notes !== undefined) {
        await mspService.editAccountNotes(apiUrl, accountToUpdate, notes);
      }
      const { mspAccounts } = getState().accountState;
      const { itemsToDisplay } = getState().accountState;
      const { viewSearchResults } = getState().generalState;
      try {
        if (!viewSearchResults) {
          const nextState = editAccountFromState(mspAccounts, itemsToDisplay, accountToUpdate, updatedAccount);
          if (nextState) {
            dispatch({
              type: AccountActionTypes.EDIT_ACCOUNT,
              itemsToDisplay: nextState.itemsToDisplay,
              mspAccounts: nextState.mspAccounts,
            });
          }
        } else {
          const { filters } = getState().accountState;
          const nextState = editAccountFromStateWhenFiltersAreOn(mspAccounts, itemsToDisplay, accountToUpdate, updatedAccount, filters);
          if (nextState) {
            if (nextState.mspAccounts === undefined) {
              dispatch({
                type: AccountActionTypes.SET_DISPLAY_ITEMS,
                itemsToDisplay: nextState.itemsToDisplay,
              });
            } else {
              dispatch({
                type: AccountActionTypes.EDIT_ACCOUNT,
                itemsToDisplay: nextState.itemsToDisplay,
                mspAccounts: nextState.mspAccounts,
              });
            }
          }
          const { stateBeforeFilterAccounts } = getState().accountState;
          if (stateBeforeFilterAccounts) {
            const nextState = editAccountFromStateSavedBeforeFiltering(stateBeforeFilterAccounts, updatedAccount);
            if (nextState) {
              dispatch({
                type: AccountActionTypes.SAVE_DISPLAY_STATE,
                stateBeforeFilterAccounts: nextState,
              });
            }
          }
        }
        const { accountsNames } = getState().accountState;
        const nextStateAccountsNames = addUpdateAccountToAccountNamesList(accountsNames, {
          id: accountToUpdate.id,
          name: accountToUpdate.name,
          type: accountToUpdate.type,
          closestParentId: accountToUpdate.closestParentId,
        });
        dispatch({
          type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
          loadingAllChildrenAccountNames: false,
          accountsNames: nextStateAccountsNames,
        });
        const { expandedPartner } = getState().accountState;
        if (expandedPartner && expandedPartner.id === updatedAccount.id) {
          dispatch({
            type: AccountActionTypes.SET_EXPANDED_PARTNER,
            expandedPartner: updatedAccount,
          });
        }
      } catch (error) {
        console.log(error);
      }
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: AccountActionTypes.EDIT_ACCOUNT,
            itemsToDisplay: getState().accountState.itemsToDisplay,
            mspAccounts: getState().accountState.mspAccounts,
          });
        },
        () => {},
        true,
        getEditAccountItemActionType(accountToUpdate.type),
      );
      return false;
    }
  };
};

export const deleteAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeleteAccountAction>> = (account: IAccount, deleteAccountSerials: IDeleteAccountSerial[] | undefined) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      await mspService.deleteAccount(apiUrl, account);

      try {
        const { mspAccounts } = getState().accountState;
        const { viewSearchResults } = getState().generalState;
        const { accountsProducts } = getState().productState;
        const updatedProducts = removeAccountSerialsFromState(account, deleteAccountSerials, accountsProducts, mspAccounts);
        if (updatedProducts !== undefined) {
          dispatch({
            type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
            accountsProducts: updatedProducts,
          });
        }

        if (!viewSearchResults) {
          const nextState = deleteAccountFromState(mspAccounts, account);
          if (nextState) {
            dispatch({
              type: AccountActionTypes.DELETE_ACCOUNT,
              itemsToDisplay: nextState.itemsToDisplay,
              mspAccounts: nextState.mspAccounts,
            });
            if (nextState.wasLastSubpartner) {
              dispatch({ type: GeneralActionTypes.SET_HAS_SUBPARTNERS, hasSubpartners: false });
            }
          }
        } else {
          const { itemsToDisplay } = getState().accountState;
          const nextState = deleteAccountFromStateWhenFiltersAreOn(mspAccounts, account, itemsToDisplay);
          if (nextState) {
            if (nextState.mspAccounts === undefined) {
              dispatch({
                type: AccountActionTypes.SET_DISPLAY_ITEMS,
                itemsToDisplay: nextState.itemsToDisplay,
              });
            } else {
              dispatch({
                type: AccountActionTypes.DELETE_ACCOUNT,
                itemsToDisplay: nextState.itemsToDisplay,
                mspAccounts: nextState.mspAccounts,
              });
            }
          }
          const { stateBeforeFilterAccounts } = getState().accountState;
          if (stateBeforeFilterAccounts) {
            const nextState = deleteAccountFromStateSavedBeforeFiltering(stateBeforeFilterAccounts, account);
            if (nextState) {
              dispatch({
                type: AccountActionTypes.SAVE_DISPLAY_STATE,
                stateBeforeFilterAccounts: nextState,
              });
            }
          }
        }
        const { accountsNames } = getState().accountState;
        const nextStateAccountsNames = removeAccountFromAccountNamesList(accountsNames, account.id);
        dispatch({
          type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
          loadingAllChildrenAccountNames: false,
          accountsNames: nextStateAccountsNames,
        });
      } catch (error) {
        console.log(error);
      }

      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: AccountActionTypes.DELETE_ACCOUNT,
            itemsToDisplay: getState().accountState.itemsToDisplay,
            mspAccounts: getState().accountState.mspAccounts,
          });
        },
        () => {},
        true,
        getDeleteAccountItemActionType(account.type),
      );
      return false;
    }
  };
};

export const getAccountAddressAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAccountAddressAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const { countries } = getState().accountState;
    dispatch({
      type: AccountActionTypes.LOAD_ADDRESS,
      loadingAddress: true,
      addressToDisplay: undefined,
    });
    if (account.type === MspType.BillingAggregator) {
      cancelGeneralActionTokenAndCreateNew(getState, dispatch);
    }
    const newCancelTokenSource = cancelCurrentGetAddressActionTokenAndCreateNew(getState, dispatch);

    try {
      const address = await mspService.loadAccountAddress(apiUrl, account, newCancelTokenSource.token);
      if (isUK(address?.contact.country) || isUK(address?.billing.country)) {
        if (countries.length === 0) {
          const apiCountries = await mspService.loadCountries(apiUrl, newCancelTokenSource.token);
          if (apiCountries?.data?.countries) {
            const resultCountries: ICountry[] = apiCountries.data.countries;
            resultCountries.sort(dynamicSort("countryName"));
            dispatch({
              countries: resultCountries,
              type: AccountActionTypes.LOAD_COUNTRIES,
            });
            await getStatesAndUpdateCountries(resultCountries);
          }
        } else {
          let uKCountry = countries.find(c => isUK(c.countryName));
          if (!uKCountry?.states) {
            await getStatesAndUpdateCountries(countries);
          }
        }
      }
      dispatch({
        addressToDisplay: address,
        type: AccountActionTypes.LOAD_ADDRESS,
        loadingAddress: false,
      });
      return address;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.LOAD_ADDRESS,
          addressToDisplay: undefined,
          loadingAddress: false,
        });
      });
      return false;
    }

    async function getStatesAndUpdateCountries(resultCountries: ICountry[]) {
      const states = await mspService.loadStates(apiUrl, "GB", newCancelTokenSource.token);
      if (states?.data?.states) {
        const updatedCountries = updateCountriesWithStates(resultCountries, states.data.states, "GB");
        dispatch({
          countries: updatedCountries,
          type: AccountActionTypes.LOAD_STATES,
        });
      }
    }
  };
};

export const getCountriesAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetCountriesAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const countriesResult = await mspService.loadCountries(apiUrl);
      const countries: ICountry[] = countriesResult.data.countries;
      countries.sort(dynamicSort("countryName"));
      dispatch({
        countries: countries,
        type: AccountActionTypes.LOAD_COUNTRIES,
      });
      return countries;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.LOAD_COUNTRIES,
          countries: [],
        });
      });
      return [];
    }
  };
};

export const getStatesAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetStatesAction>> = (countryCode: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { countries } = getState().accountState;
    try {
      const { apiUrl } = getState().generalState;
      const states = await mspService.loadStates(apiUrl, countryCode);

      const updatedCountries = updateCountriesWithStates(countries, states.data.states, countryCode);
      dispatch({
        countries: updatedCountries,
        type: AccountActionTypes.LOAD_STATES,
      });
      return states.data.states;
    } catch (err) {
      handleError(err, dispatch, () => {});
      return false;
    }
  };
};

export const setStatesAction: ActionCreator<ThunkAction<any, IAppState, null, ISetStatesAction>> = (countryCode: string) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { countries } = getState().accountState;
    const updatedCountries = updateCountriesWithStates(countries, [], countryCode);
    dispatch({
      countries: updatedCountries,
      type: AccountActionTypes.SET_STATES,
    });
  };
};

export const setSelectedAccountAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectedAccountAction>> = (account: IAccount) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: AccountActionTypes.SET_SELECTED_ACCOUNT,
      selectedAccount: account,
      showEmptyPanel: account !== undefined ? false : true,
    });
  };
};

export const setLoadedAccountAction: ActionCreator<ThunkAction<any, IAppState, null, ISetLoadedAccountAction>> = (account: IAccount) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SET_LOADED_ACCOUNT, loadedAccount: account });

export const setExpandedPartnerAction: ActionCreator<ThunkAction<any, IAppState, null, ISetExpandedPartnerAction>> = (account: IAccount | undefined) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SET_EXPANDED_PARTNER, expandedPartner: account });

export const setAccountsPageSizeAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountsPageSizeAction>> = (accountsPageSize: number) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    console.log("accountsPageSize:" + accountsPageSize);
    const { id } = getState().generalState.loggedUser;
    dispatch({ type: AccountActionTypes.SET_PAGE_SIZE, accountsPageSize });
    localStorageService.setItem(id.toString(), LocalStoragePreferences.ACCOUNTS_UI, accountsPageSize.toString());
  };
};

export const setAccountsPageNumberAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountsPageNumberAction>> = (accountsPageNumber: number) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SET_PAGE_NUMBER, accountsPageNumber });

export const navigateToAccountAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountsPageNumberAction>> = (accountId: number) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    navigateToAccountDispatchAction(getState, dispatch, accountId);
  };
};

export const isAccountInDisplayedItemsAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountsPageNumberAction>> = (account: IAccount) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { itemsToDisplay } = getState().accountState;
    const newIndex = itemsToDisplay.findIndex(x => x.id.toString() === account.id.toString());
    if (newIndex > -1) {
      return true;
    }
    return false;
  };
};

export const adjustAccountsCurrentPageAfterDeleteAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAccountsPageNumberAction>> = () => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { itemsToDisplay } = getState().accountState;
    const { accountsPageNumber } = getState().accountState;
    const { accountsPageSize } = getState().accountState;
    const adjustedAccountsPageNumber = getAdjustedPageNumberAfterDelete(itemsToDisplay.length, accountsPageSize, accountsPageNumber);
    dispatch({ type: AccountActionTypes.SET_PAGE_NUMBER, accountsPageNumber: adjustedAccountsPageNumber });
  };
};

export const setDisplayMSPAccountsAction: ActionCreator<ThunkAction<any, IAppState, null, ISetDisplayItemsAction>> = () => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccounts } = getState().accountState;
    const itemsToDisplay = getMspAccountsToDisplay(mspAccounts);
    dispatch({
      type: AccountActionTypes.SET_DISPLAY_ITEMS,
      itemsToDisplay: itemsToDisplay,
    });
  };
};

export const setDisplayCustomersByAccountId: ActionCreator<ThunkAction<any, IAppState, null, ISetDisplayItemsAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    setDisplayCustomersByAccountIdDispatchAction(getState, dispatch, account);
  };
};
export const goToAccountAction: ActionCreator<ThunkAction<any, IAppState, null, IGoToAccountAction>> = (account: IAccount) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccounts, accountsPageSize } = getState().accountState;
    const result = goToAccount(mspAccounts, accountsPageSize, account);
    dispatch({
      type: AccountActionTypes.GO_TO_ACCOUNT_ACTION,
      selectedAccount: result.selectedAcc,
      itemsToDisplay: result.itemsToDisplay,
      expandedPartner: result.newExpandedPartner,
      accountsPageNumber: result.accountsPageNumber,
    });
  };
};

export const goToAccountIdAction: ActionCreator<ThunkAction<any, IAppState, null, IGoToAccountAction>> = (accId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const result = await prepareAccountsListDispatchAction(getState, accId);
    const { viewSearchResults } = getState().generalState;
    if (result) {
      if (!viewSearchResults) {
        const { mspAccountLoggedIn } = getState().generalState;
        if (!(mspAccountLoggedIn.type === MspType.Partner && result.selectedAcc?.type === MspType.Subpartner)) {
          dispatch({
            type: AccountActionTypes.GO_TO_ACCOUNT_ACTION,
            selectedAccount: result.selectedAcc,
            itemsToDisplay: result.itemsToDisplay,
            expandedPartner: result.newExpandedPartner,
            accountsPageNumber: result.accountsPageNumber,
          });
        }
      }

      onSelectAccountItemDispatchAction(getState, dispatch, result.selectedAcc);
    }
  };
};

export const onSelectAccountItemAction: ActionCreator<ThunkAction<any, IAppState, null, IGoToAccountAction>> = (item: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    return await onSelectAccountItemDispatchAction(getState, dispatch, item);
  };
};

export const goBackToViewMspAccountsAction: ActionCreator<ThunkAction<any, IAppState, null, IGoToAccountAction>> = (prevSelected: IAccount, prevExpanded: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    return await goBackToViewMspAccountsDispatchAction(getState, dispatch, prevSelected, prevExpanded);
  };
};

export const setLoadingFilteringAccounts: ActionCreator<ThunkAction<any, IAppState, null, ISetLoadingFilteredAccounts>> = (loading: boolean) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS, loadingFilteredAccounts: loading });

export const setM365AuthAction: ActionCreator<ThunkAction<any, IAppState, null, ISetM365Auth>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      return await mspService.addM365Auth(apiUrl, account);
    } catch (err) {
      console.log(err);
      handleError(
        err,
        dispatch,
        () => {},
        () => {},
        true,
        ActionTypes.LinkAccountToM365,
      );
      return false;
    }
  };
};

export const revokeM365AuthAction: ActionCreator<ThunkAction<any, IAppState, null, ISetM365Auth>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      return await mspService.revokeM365Auth(apiUrl, account);
    } catch (err) {
      console.log(err);
      handleError(
        err,
        dispatch,
        () => {},
        () => {},
        true,
        ActionTypes.UnlinkAccountFromM365,
      );
      return false;
    }
  };
};

export const fetchM365AuthAction: ActionCreator<ThunkAction<any, IAppState, null, IFetchM365Auth>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: AccountActionTypes.SET_LOADING_ACCOUNT_M365_AUTH,
        loadingAccountM365Auth: true,
      });
      const result = await mspService.fetchM365Auth(apiUrl, account);
      try {
        const nextState = updateAccountM365AuthFromState(account, result);
        const curSelected = getState().accountState.selectedAccount;
        if (curSelected?.id === account.id) {
          dispatch({
            type: AccountActionTypes.FETCH_M365_AUTH,
            selectedAccount: { ...curSelected, m365AuthLinked: nextState.m365AuthLinked, m365AuthUpdateTime: nextState.m365AuthUpdateTime },
            loadingAccountM365Auth: false,
          });
        } else {
          dispatch({
            type: AccountActionTypes.SET_LOADING_ACCOUNT_M365_AUTH,
            loadingAccountM365Auth: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        const curSelected = getState().accountState.selectedAccount;
        if (curSelected?.id === account.id) {
          dispatch({
            type: AccountActionTypes.FETCH_M365_AUTH,
            selectedAccount: curSelected,
            loadingAccountM365Auth: false,
          });
        }
      });
      return false;
    }
  };
};

export const fetchM365AuthStatusAction: ActionCreator<ThunkAction<any, IAppState, null, IFetchM365AuthForAcc>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountsNames } = getState().accountState;
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: AccountActionTypes.FETCH_M365_AUTH_FOR_ACC,
        loadingM356StatusForAccountId: account.id,
        accountsNames: accountsNames,
      });
      const result = await mspService.fetchM365Auth(apiUrl, account);
      const nextState = updateAccountListWithM365Status(accountsNames, account, result);
      dispatch({
        type: AccountActionTypes.FETCH_M365_AUTH_FOR_ACC,
        loadingM356StatusForAccountId: undefined,
        accountsNames: nextState,
      });
      return result;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.FETCH_M365_AUTH_FOR_ACC,
          loadingM356StatusForAccountId: undefined,
          accountsNames: accountsNames,
        });
      });
      return undefined;
    }
  };
};

export const saveStateBeforeFilterAction: ActionCreator<ThunkAction<any, IAppState, null, ISaveStateBeforeFilterAccounts>> = (stateBeforeFilterAccounts: IDisplayState | undefined) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SAVE_DISPLAY_STATE, stateBeforeFilterAccounts });

export const fetchM365DomainListAction: ActionCreator<ThunkAction<any, IAppState, null, IFetchM365DomainList>> = (account: IAccount, skip: number, take: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const domains = await mspService.fetchM365DomainList(apiUrl, account, skip, take);
      if (!domains.isSuccessful) {
        throw domains.error;
      }
      return domains;
    } catch (err) {
      console.log(err);
      throw new Error("An error occurred communicating with M365.");
    }
  };
};

export const fetchProductLicensesAction: ActionCreator<ThunkAction<any, IAppState, null, IFetchProductLicenses>> = (account: IAccount, user: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const products = await mspService.fetchProduceLicenses(apiUrl, account, user);
      if (!products.isSuccessful) {
        throw products.error;
      }
      return products;
    } catch (err) {
      console.log(err);
      throw new Error("An error occurred communicating with M365.");
    }
  };
};

export const getLastUsageSyncTimeAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetLastUsageSyncTimeAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const { LastUsageSyncTime } = await mspService.fetchLastUsageSyncTime(apiUrl);
      dispatch({
        lastUsageSyncTime: LastUsageSyncTime,
        type: AccountActionTypes.SET_LAST_USAGE_SYNC_TIME,
      });
      return LastUsageSyncTime;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.SET_LAST_USAGE_SYNC_TIME,
          lastUsageSyncTime: "",
        });
      });
      return "";
    }
  };
};

export const getCustomersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetMspAccounts>> = (accountId: number, updateItemsToDisplay: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    return await getCustomersDispatchAction(getState, dispatch, accountId, updateItemsToDisplay);
  };
};

export const cancelLoadSubpartnerCustomersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelLoadSubpartnerCustomersTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    cancelLoadSubpartnerCustomersDispatchAction(getState, dispatch);
  };
};

export const cancelLoadPartnerChildrenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelLoadCustomerParentAndItsChildrenTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const cancelTokenSource = getState().accountState.childrenCancellationTokenSource;
    cancelCurrent(cancelTokenSource);
    dispatch({
      childrenCancellationTokenSource: undefined,
      type: AccountActionTypes.SET_CANCEL_LOAD_CHILDREN_TOKEN,
    });
  };
};

export const cancelLoadCustomerParentAndItsChildrenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelLoadCustomerParentAndItsChildrenTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const cancelTokenSource = getState().accountState.loadCustomerParentAndItsChildrenCancellationTokenSource;
    if (cancelTokenSource) {
      cancelTokenSource?.cancel("Operation canceled.");
    }
    dispatch({
      loadCustomerParentAndItsChildrenCancellationTokenSource: undefined,
      type: AccountActionTypes.SET_CANCEL_LOAD_CUSTOMER_PARENT_AND_ITS_CHILDREN_TOKEN,
    });
  };
};

export const setSelectedAccountToFilterProductsForBA: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectedAccountToFilterProductsForBA>> = (account: IAccount) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SET_SELECTED_ACCOUNT_TO_FILTER_PRODUCTS_FOR_BA, selectedAccountToFilterProductsForBA: account });

export const setshowEmptyPanelAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetShowEmptyPanelAction>> = (showEmptyPanel: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      showEmptyPanel: showEmptyPanel,
      type: AccountActionTypes.SET_SHOW_EMPTY_PANEL,
    });
  };
};

export const setAccountFiltersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetAccountFiltersAction>> = (filters: IAccountFilters | undefined) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      filters: filters,
      type: AccountActionTypes.SET_ACCOUNT_FILTERS,
    });
  };
};

export const setOwnerAccountFiltersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetFilterChildrenOfAccountIdAction>> = (filterChildrenOfAccountId: number, directChildrenOnly: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      filterChildrenOfAccountId: filterChildrenOfAccountId,
      directChildrenOnly: directChildrenOnly,
      type: AccountActionTypes.SET_FITLER_CHILDREN_OF_ACCOUNT_ID,
    });
  };
};

export const getAccountEchoUserNameAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ILoadAccountEchoUserName>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      dispatch({
        type: AccountActionTypes.LOAD_ACCOUNT_ECHO_USERNAME,
        loadingEchoUserName: true,
        echoUserName: "",
      });
      const newCancelTokenSource = cancelLoadEchoUsernameActionTokenAndCreateNew(getState, dispatch);
      const result = await mspService.loadAccountEchoUserName(apiUrl, account.id, newCancelTokenSource.token);
      dispatch({
        type: AccountActionTypes.LOAD_ACCOUNT_ECHO_USERNAME,
        loadingEchoUserName: false,
        echoUserName: result.echoUsername,
      });
      return result.echoUsername;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.LOAD_ACCOUNT_ECHO_USERNAME,
          loadingEchoUserName: false,
          echoUserName: "",
        });
      });
      return false;
    }
  };
};

export const setPrevSelectedAccountAction: ActionCreator<ThunkAction<any, IAppState, null, ISetPrevSelectedAccount>> = (account: IAccount) => (dispatch: Dispatch) => dispatch({ type: AccountActionTypes.SET_PREV_SELECTED_ACCOUNT, prevSelectedAccount: account });

function saveStateBeforeFiltering(getState: () => IAppState, dispatch: Dispatch) {
  const { stateBeforeFilterAccounts } = getState().accountState;
  if (stateBeforeFilterAccounts === undefined) {
    const { accountsPageSize } = getState().accountState;
    const { accountsPageNumber } = getState().accountState;
    const { expandedPartner } = getState().accountState;
    const { itemsToDisplay } = getState().accountState;

    dispatch({
      type: AccountActionTypes.SAVE_DISPLAY_STATE,
      stateBeforeFilterAccounts: { itemsToDisplay, accountsPageSize, accountsPageNumber, expandedPartner },
    });
  }
}

function applyFilterResultsToState(dispatch: Dispatch, results: IAccount[], mspAccountLoggedIn: IAccount) {
  const filteredResults = filterResultsByType(results, mspAccountLoggedIn);
  filteredResults.sort(dynamicSort("name"));
  dispatch({
    type: AccountActionTypes.SET_DISPLAY_ITEMS,
    itemsToDisplay: filteredResults,
  });
  dispatch({
    type: GeneralActionTypes.SET_CUSTOMER_SEARCH_RESULTS,
    viewSearchResults: true,
  });
  dispatch({
    type: AccountActionTypes.SET_PAGE_NUMBER,
    accountsPageNumber: 1,
  });
  dispatch({
    type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
    loadingFilteredAccounts: false,
  });
  dispatch({
    type: AccountActionTypes.SET_SHOW_EMPTY_PANEL,
    showEmptyPanel: true,
  });
}

export const cancelCurrentFilterAccountsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelFilterAccountsTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    cancelFilterAccountsActionToken(getState, dispatch);
  };
};

export const filterAccountsAction: ActionCreator<ThunkAction<any, IAppState, null, ISetDisplayItemsAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    filterAccounts(getState, dispatch);
  };
};

export async function filterAccounts(getState: () => IAppState, dispatch: Dispatch<any>) {
  const { filters } = getState().accountState;
  if (filters) {
    const filterMethod = getFilterMethod(filters);

    const cancelTokenSource = getState().accountState.filterAccountsCancellationTokenSource;
    if (!filterMethod.filterByName && !filterMethod.filterByExtraParams) {
      cancelCurrent(cancelTokenSource);
      resetToPrevStateBeforeFiltering(getState, dispatch);
      return;
    } else {
      saveStateBeforeFiltering(getState, dispatch);
      if (filterMethod.filterByExtraParams) {
        const { directChildrenOnly } = getState().accountState;
        const { filterChildrenOfAccountId } = getState().accountState;
        if (filterChildrenOfAccountId) {
          if (filterMethod.filterByName) {
            filterByNameAndExtraParams(getState, dispatch, filterChildrenOfAccountId, filters, directChildrenOnly);
          } else {
            filterOnlyByExtraParams(getState, dispatch, filterChildrenOfAccountId, filters, directChildrenOnly);
          }
        }
      } else {
        cancelCurrent(cancelTokenSource);
        filterOnlyByName(getState, dispatch, filters.name);
      }
    }
  }
}

export const getAccountInfoAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAccountInfoStatus>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const { notes } = getState().accountState;
    try {
      dispatch({
        type: AccountActionTypes.GET_ACCOUNT_INFO,
        loadingAccountInfo: true,
        hasIBU: false,
      });
      const newCancelTokenSource = cancelLoadAccountInfoActionTokenAndCreateNew(getState, dispatch);
      let resultIBU;
      let resultNotes;
      if (account.type !== MspType.BillingAggregator) {
        let result = await mspService.getAccountInfo(apiUrl, account.id, newCancelTokenSource.token);
        resultIBU = result.hasIBU;
        resultNotes = result.notes;
      }
      dispatch({
        type: AccountActionTypes.GET_ACCOUNT_INFO,
        loadingAccountInfo: false,
        hasIBU: resultIBU || false,
        ...(account.type.includes(MspType.Customer) && { notes: resultNotes }),
      });
      return resultIBU;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AccountActionTypes.GET_ACCOUNT_INFO,
          loadingAccountInfo: false,
          hasIBU: false,
          notes,
        });
      });
      return false;
    }
  };
};

export const cancelLoadAccountInfoActionTokenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelLoadAccountInfoToken>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const cancelTokenSource = getState().accountState.accountInfoCancellationTokenSource;
    if (cancelTokenSource) {
      cancelTokenSource?.cancel("Operation canceled.");
    }
    dispatch({
      accountInfoCancellationTokenSource: undefined,
      type: AccountActionTypes.SET_CANCEL_LOAD_ACCOUNT_INFO_TOKEN,
    });
  };
};

function filterOnlyByName(getState: () => IAppState, dispatch: Dispatch, name?: string) {
  if (name !== undefined) {
    const { isBaLoggedIn, mspAccountLoggedIn } = getState().generalState;
    const { mspAccounts, accountsNames } = getState().accountState;
    if (isBaLoggedIn) {
      const result = searchAccount(mspAccountLoggedIn, mspAccounts, name);
      applyFilterResultsToState(dispatch, result, mspAccountLoggedIn);
    } else {
      const { filterChildrenOfAccountId, directChildrenOnly } = getState().accountState;
      const itemsToFilter = computeItemsToFilter(mspAccountLoggedIn, accountsNames, mspAccounts, filterChildrenOfAccountId, directChildrenOnly);
      const result = filterSmbByName(itemsToFilter, name);
      const mappedResults = mapParentAccountNameToFilterResults(result, mspAccounts);
      applyFilterResultsToState(dispatch, mappedResults, mspAccountLoggedIn);
    }
  }
}

async function filterOnlyByExtraParams(getState: () => IAppState, dispatch: Dispatch, accountId: number, filters: IAccountFilters, filterOnlyDirectAccounts?: boolean) {
  try {
    dispatch({
      type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
      loadingFilteredAccounts: true,
    });
    dispatch({
      type: AccountActionTypes.SET_SHOW_EMPTY_PANEL,
      showEmptyPanel: true,
    });
    const newCancelTokenSource = cancelFilterAccountsActionTokenAndCreateNew(getState, dispatch);
    const { apiUrl, mspAccountLoggedIn } = getState().generalState;
    const { accountsNames, mspAccounts } = getState().accountState;
    const results = await mspService.filterAccounts(apiUrl, accountId, filters, filterOnlyDirectAccounts, newCancelTokenSource.token);
    const resultNameUpdated = getNamesForFilteredAccounts(results, accountsNames);
    const mappedResults = mapParentAccountNameToFilterResults(resultNameUpdated, mspAccounts);
    applyFilterResultsToState(dispatch, mappedResults, mspAccountLoggedIn);
  } catch (err) {
    handleError(
      err,
      dispatch,
      () => {},
      () => {},
    );
  }
}

async function filterByNameAndExtraParams(getState: () => IAppState, dispatch: Dispatch, accountId: number, filters: IAccountFilters | undefined, filterOnlyDirectAccounts?: boolean) {
  if (filters?.name !== undefined) {
    const { apiUrl, mspAccountLoggedIn } = getState().generalState;
    const { accountsNames, mspAccounts, filterChildrenOfAccountId, directChildrenOnly, filterAccountsCancellationTokenSource } = getState().accountState;
    const itemsToFilter = computeItemsToFilter(mspAccountLoggedIn, accountsNames, mspAccounts, filterChildrenOfAccountId, directChildrenOnly);
    const accFilteredByName = filterSmbByName(itemsToFilter, filters.name);
    if (accFilteredByName.length > 0) {
      dispatch({
        type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
        loadingFilteredAccounts: true,
      });
      dispatch({
        type: AccountActionTypes.SET_SHOW_EMPTY_PANEL,
        showEmptyPanel: true,
      });
      try {
        const newCancelTokenSource = cancelFilterAccountsActionTokenAndCreateNew(getState, dispatch);
        const results = await mspService.filterAccounts(apiUrl, accountId, filters, filterOnlyDirectAccounts, newCancelTokenSource.token);
        const resultNameUpdated = getNamesForFilteredAccounts(results, accountsNames);
        if (resultNameUpdated.length > 0) {
          const results1 = filterSmbByName(resultNameUpdated, filters.name);
          const mappedResults = mapParentAccountNameToFilterResults(results1, mspAccounts);
          applyFilterResultsToState(dispatch, mappedResults, mspAccountLoggedIn);
        } else {
          applyFilterResultsToState(dispatch, [], mspAccountLoggedIn);
        }
      } catch (err) {
        handleError(
          err,
          dispatch,
          () => {},
          () => {},
        );
      }
    } else {
      cancelCurrent(filterAccountsCancellationTokenSource);
      applyFilterResultsToState(dispatch, [], mspAccountLoggedIn);
    }
  }
}

function resetToPrevStateBeforeFiltering(getState: () => IAppState, dispatch: Dispatch) {
  const { stateBeforeFilterAccounts } = getState().accountState;
  const { selectedAccount } = getState().accountState;
  if (stateBeforeFilterAccounts) {
    resetToSavedState(stateBeforeFilterAccounts, selectedAccount, dispatch);
  } else {
    resetToInitialView(getState, dispatch);
  }
}

function resetToSavedState(stateBeforeFilterAccounts: IDisplayState, selectedAccount: IAccount | undefined, dispatch: Dispatch) {
  dispatch({
    type: GeneralActionTypes.SET_CUSTOMER_SEARCH_RESULTS,
    viewSearchResults: false,
  });
  if (stateBeforeFilterAccounts.expandedPartner) {
    dispatch({
      type: AccountActionTypes.SET_EXPANDED_PARTNER,
      expandedPartner: stateBeforeFilterAccounts.expandedPartner,
    });
  }
  dispatch({
    type: AccountActionTypes.SET_DISPLAY_ITEMS,
    itemsToDisplay: stateBeforeFilterAccounts.itemsToDisplay,
  });
  dispatch({
    type: AccountActionTypes.SET_PAGE_SIZE,
    accountsPageSize: stateBeforeFilterAccounts.accountsPageSize,
  });
  dispatch({
    type: AccountActionTypes.SET_PAGE_NUMBER,
    accountsPageNumber: stateBeforeFilterAccounts.accountsPageNumber,
  });
  dispatch({
    type: AccountActionTypes.SAVE_DISPLAY_STATE,
    stateBeforeFilterAccounts: undefined,
  });
  dispatch({
    type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
    loadingFilteredAccounts: false,
  });
  if (selectedAccount) {
    const idIndex = stateBeforeFilterAccounts.itemsToDisplay.findIndex(x => x.id === selectedAccount.id);
    if (idIndex > -1) {
      dispatch({
        type: AccountActionTypes.SET_SHOW_EMPTY_PANEL,
        showEmptyPanel: false,
      });
      const accountsPageNumber = setAccountsPageNumberForAccountId(stateBeforeFilterAccounts.itemsToDisplay, stateBeforeFilterAccounts.accountsPageSize, selectedAccount.id);
      dispatch({ type: AccountActionTypes.SET_PAGE_NUMBER, accountsPageNumber });
    } else {
      dispatch({
        type: AccountActionTypes.SET_SELECTED_ACCOUNT,
        selectedAccount: undefined,
      });
      dispatch({
        type: AccountActionTypes.SET_SHOW_EMPTY_PANEL,
        showEmptyPanel: true,
      });
    }
  } else {
    const adjustedAccountsPageNumber = getAdjustedPageNumberAfterDelete(stateBeforeFilterAccounts.itemsToDisplay.length, stateBeforeFilterAccounts.accountsPageSize, stateBeforeFilterAccounts.accountsPageNumber);
    dispatch({ type: AccountActionTypes.SET_PAGE_NUMBER, accountsPageNumber: adjustedAccountsPageNumber });
  }
}

function resetToInitialView(getState: () => IAppState, dispatch: Dispatch) {
  const { mspAccounts } = getState().accountState;
  const { isBaLoggedIn } = getState().generalState;
  if (isBaLoggedIn) {
    const { mspAccountLoggedIn } = getState().generalState;
    const children = mspAccountLoggedIn.accounts;
    const itemsToDisplay = children !== undefined ? [mspAccountLoggedIn, ...children] : [mspAccountLoggedIn];
    dispatch({
      type: AccountActionTypes.SET_DISPLAY_ITEMS,
      itemsToDisplay: itemsToDisplay,
    });
  } else {
    const itemsToDisplay = getMspAccountsToDisplay(mspAccounts);
    dispatch({
      type: AccountActionTypes.SET_DISPLAY_ITEMS,
      itemsToDisplay: itemsToDisplay,
    });
    dispatch({
      type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS,
      loadingFilteredAccounts: false,
    });
  }
  dispatch({
    type: GeneralActionTypes.SET_CUSTOMER_SEARCH_RESULTS,
    viewSearchResults: false,
  });
}

export const getItemToNavigateToAfterDeleteAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelLoadAccountInfoToken>> = (item: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccountLoggedIn, hasSubpartners } = getState().generalState;
    if (mspAccountLoggedIn.type === MspType.Partner && item?.type === MspType.Customer && hasSubpartners) {
      return Promise.resolve(item.closestParentId);
    }
    return Promise.resolve(undefined);
  };
};

export const updateBaMspWithChildren: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAllChildrenAccountNames>> = (mspAccount: IAccount, allChildren: IAccount[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccounts } = getState().accountState;
    const { itemsToDisplay } = getState().accountState;
    const mspAccountIndex = mspAccounts.findIndex(x => x.id === mspAccount.id);
    if (mspAccountIndex > -1) {
      let subpartners = allChildren?.filter(x => x.type === MspType.Subpartner && x.closestParentId === mspAccount.id);
      subpartners.sort(dynamicSort("name"));
      let accounts = allChildren?.filter(x => x.type === MspType.Customer && x.closestParentId === mspAccount.id);
      accounts.sort(dynamicSort("name"));
      const updatedSubpartners = updateMspAccountsWithChildren(subpartners, allChildren);
      const updatedAccounts = [...updatedSubpartners, ...accounts];
      const nextStateMspAccounts: IAccount[] = produce(mspAccounts, (draft: IAccount[]) => {
        draft[mspAccountIndex].accounts = updatedAccounts;
      });

      dispatch({
        type: AccountActionTypes.SET_MSP_ACCOUNTS,
        itemsToDisplay: itemsToDisplay,
        mspAccounts: nextStateMspAccounts,
      });
    }
  };
};
