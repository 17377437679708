import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import DropDown from "../DropDown";
import IProduct from "../../models/Products/IProduct";
import GroupedDropDown from "../GroupedDropDown";
import IGroupedDropDownSuboption from "../../models/IGroupedDropDownSuboption";
import { IProductFamillyNames } from "../../models/Products/ProductFamily";
import { getBbsDisplayValue, getBbsOptions, getDisplayProductFamilyNames, groupByFamilyAndByBundleName, isBBS } from "../../Utilities/productsHelper";
import { enterKey, handleBackdropClick } from "../../utility";

interface ISubmitDialogProps {
  productsToActivate: IProduct[];
  onSubmit: (product: IProduct) => void;
  onCancel: () => void;
  showDialog: boolean;
  actionInProgress: boolean;
  dialogTitle: string;
}

const ActivateAssignProductDialog: React.FC<ISubmitDialogProps> = ({ productsToActivate, showDialog, onCancel, onSubmit, actionInProgress, dialogTitle }) => {
  const label = "Product/Service";
  const labelFamily = "Product/Service Family";
  const [activateProductFamilyError, setactivateProductFamilyError] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [productFamilies, setProductFamilies] = useState<IProductFamillyNames[]>([]);
  const [BBSOptions, setBBSOptions] = useState<string[]>([]);
  const [groupedOptions, setGroupedOptions] = useState<Record<string, IGroupedDropDownSuboption[]>[]>([]);
  const [selectedProductFamilyId, setSelectedProductFamilyId] = useState(0);
  const [selectedOptionId, setSelectedOptionId] = useState("--Select--");
  const [selectedBBSOptionId, setSelectedBBSOptionId] = useState(0);
  const [activateProductsError, setActivateProductsError] = useState("");
  const [isBBSSelected, setIsBBSSelected] = useState(false);
  const [firstDropdownIsOpen, setFirstDropdownIsOpen] = useState(false);
  const [secondDropdownIsOpen, setSecondDropdownIsOpen] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);

  useEffect(() => {
    setConfirmButtonDisabled(selectedOptionId === "--Select--" || (isBBS(selectedProductFamilyId, productFamilies) && selectedBBSOptionId === 0) || actionInProgress);
  }, [selectedOptionId, selectedBBSOptionId, selectedProductFamilyId, productFamilies, actionInProgress]);

  const handleOnActivateClicked = () => {
    let isError = false;
    if (selectedProductFamilyId === 0) {
      isError = true;
      setactivateProductFamilyError("Select a product/service family");
    } else if (selectedOptionId === "--Select--") {
      isError = true;
      setActivateProductsError("Select a product/service");
    }
    if (selectedProduct && !isError) {
      onSubmit(selectedProduct);
    }
  };

  useEffect(() => {
    setProductFamilies(getDisplayProductFamilyNames(productsToActivate));
  }, [productsToActivate]);

  useEffect(() => {
    setIsBBSSelected(isBBS(selectedProductFamilyId, productFamilies));
  }, [productFamilies, selectedProductFamilyId]);

  const onProductFamilySelected = (selProdFamId: number) => {
    setactivateProductFamilyError("");
    setSelectedProductFamilyId(selProdFamId);
    if (isBBS(selProdFamId, productFamilies)) {
      setSelectedBBSOptionId(0);
      setBBSOptions(getBbsOptions(productsToActivate));
    } else {
      setSelectedOptionId("--Select--");
      const filteredProducts = productsToActivate.filter((x: IProduct) => parseInt(x.provisionable ?? "") === 1);
      setGroupedOptions(groupByFamilyAndByBundleName(filteredProducts, productFamilies[selProdFamId - 1].productFamily));
    }
    setSelectedProduct(undefined);
    setActivateProductsError("");
  };

  const onProductsSelected = (value: string) => {
    setSelectedOptionId(value);
    const currentProduct = productsToActivate.filter((x: IProduct) => x.id === value)[0];
    setSelectedProduct(currentProduct);
    setActivateProductsError("");
  };

  const onBBSProductsSelected = (value: number) => {
    const currentOption = BBSOptions[value - 1];
    const currentProduct = productsToActivate.filter((x: IProduct) => getBbsDisplayValue(x) === currentOption)[0];
    setSelectedBBSOptionId(value);
    setSelectedOptionId(currentProduct.id);
    setSelectedProduct(currentProduct);
    setActivateProductsError("");
  };

  const handleOnOpenFirstDropdown = (isOpen: boolean) => {
    setFirstDropdownIsOpen(isOpen);
  };

  const handleOnOpenSecondDropdown = (isOpen: boolean) => {
    setSecondDropdownIsOpen(isOpen);
  };

  return (
    <Dialog onKeyUp={(event: any) => (!firstDropdownIsOpen && !secondDropdownIsOpen ? enterKey(event, handleOnActivateClicked) : "")} disableEscapeKeyDown={actionInProgress} className={"ActivateAssignDialog"} data-testid="activateAssignDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} fullWidth>
      <DialogTitle data-testid="activateAssignDialogTitle">{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <div data-testid="assignActivateProductFamiliesDropdown">
              <DropDown label={labelFamily} options={productFamilies.map(x => x.displayName)} selectedOptionId={selectedProductFamilyId} error={activateProductFamilyError} handleChange={onProductFamilySelected} disabled={actionInProgress} onOpen={handleOnOpenFirstDropdown} required={false} />
            </div>
          </Grid>
          {isBBSSelected ? (
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <div data-testid="assignActivateDropdown">
                <DropDown label={label} options={BBSOptions} selectedOptionId={selectedBBSOptionId} error={activateProductsError} handleChange={onBBSProductsSelected} disabled={actionInProgress} onOpen={handleOnOpenSecondDropdown} required={false} />
              </div>
            </Grid>
          ) : (
            selectedProductFamilyId > 0 && (
              <Grid item xs={12} style={{ paddingTop: 20 }}>
                <div data-testid="assignActivateDropdown">
                  <GroupedDropDown label={label} options={groupedOptions} selectedOptionId={selectedOptionId} error={activateProductsError} handleChange={onProductsSelected} disabled={actionInProgress} onOpen={handleOnOpenSecondDropdown} required={false} />
                </div>
              </Grid>
            )
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button data-testid="activateAssignCancelButton" disabled={actionInProgress} variant="text" size="large" onClick={onCancel}>
          CANCEL
        </Button>
        <Button data-testid="activateAssignConfirmButton" disabled={confirmButtonDisabled} isLoading={actionInProgress} primary="true" type={"submit"} onClick={() => handleOnActivateClicked()}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateAssignProductDialog;
