import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import React, { useEffect, useState } from "react";
import ISerial from "../../../models/Products/ISerial";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../../../store/store";
import { enterKey, handleBackdropClick, isStringNullOrEmpty, setNoPoinerEvents, userHasRole } from "../../../utility";
import UserRole from "../../../models/UserRole";
import SerialStatus from "../../../models/Products/SerialStatus";
import ProductFamily from "../../../models/Products/ProductFamily";
import IProduct from "../../../models/Products/IProduct";
import IAccount from "../../../models/IAccount";
import IProductFamily from "../../../models/Products/IProductFamily";
import SerialStatusIcon from "../SerialStatusIcon";
import { displayChangeServicesButton, displayRemoveSerialButton, getEditSerialsModalGridClassName, getEditSerialsModalStatusClassName, getProductsAvailableToActivate, isSecureEdgeServiceSku, isStatusActivationFailed, isStatusPending, productIsBBS } from "../../../Utilities/productsHelper";
import { Tooltip } from "@material-ui/core";
import { getSerialStatusIconTooltip } from "../../../businessLogic/components/Products/SerialStatusIcon";
import { NewProductBundles } from "../../../Utilities/NewProductBundles";
import BackTo from "../../BackTo";
import BandwidthCell from "./BandwidthCell";
import { fetchChangeSerialOptionsAction } from "../../../actions/productActions";

interface ISubmitDialogProps {
  onCancel: () => void;
  onDeactivate: () => void;
  onUnassign: () => void;
  onRetryActivation: () => void;
  onManageService: () => void;
  showDialog: boolean;
  serialForUser: ISerial;
  displayLink: boolean;
  product: IProduct;
  showUnassignButton: boolean;
  handleOpenManageServiceDialog: (optionIndex: number) => void;
  onHandleBackToMSPAccounts: (refreshSerials: boolean) => void;
  onMigrationOptionsCalculated: (options: IProduct[]) => void;
  isActionInProgress: boolean;
  isLoadingParent: boolean;
  parentIsNotMsp: boolean;
  isSecureEdgeProducts: boolean;
}

const ShowEditSerialsDialog: React.FC<ISubmitDialogProps> = ({ showDialog, product, isActionInProgress, onCancel, onDeactivate, onUnassign, onRetryActivation, serialForUser, displayLink, handleOpenManageServiceDialog, onHandleBackToMSPAccounts, onMigrationOptionsCalculated, onManageService, showUnassignButton, isLoadingParent, parentIsNotMsp, isSecureEdgeProducts }) => {
  const dispatch = useDispatch();
  const parentProducts = useSelector((state: IAppState) => state.productState.parentProductsFamilies);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const loadingProducts = useSelector((state: IAppState) => state.productState.loadingProducts);
  const loadingParentMspProducts = useSelector((state: IAppState) => state.productState.loadingParentMspProducts);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const isSubpartnerAssigned = product.subPartnerId !== null && isStringNullOrEmpty(product.account);
  const [migrateOptions, setMigrateOptions] = useState<IProduct[]>([]);
  const [migrateToolTip, setMigrateToolTip] = useState("");
  const [showChangeServiceButton, setShowChangeServiceButton] = useState(false);
  const [showRemoveSerialButton, setShowRemoveSerialButton] = useState(false);
  const isPending = isStatusPending(serialForUser.status);
  const isActivationFailed = isStatusActivationFailed(serialForUser.status);
  const isBBS = productIsBBS(product);
  const [removeSerialButtonLabel, setRemoveSerialButtonLabel] = useState("REMOVE SERIAL");
  const [loadingMigrateOptions, setLoadingMigrateOptions] = useState(false);

  const handleBackToMSPAccounts = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onHandleBackToMSPAccounts(true);
  };

  useEffect(() => {
    if (serialForUser.status === SerialStatus.ACTIVE && parentProducts.accountId === selectedAccount?.closestParentId && serialForUser.orderLineItemId === product.id) {
      if (!userHasRole(UserRole.Admin, mspAccountLoggedIn) || selectedAccount?.id.toString() !== serialForUser.accountId.toString()) {
        setMigrateOptions([]);
        onMigrationOptionsCalculated([]);
        setMigrateToolTip("No valid options to change to.");
      } else {
        setLoadingMigrateOptions(true);
        new Promise<any>((resolve, reject) => {
          const result = dispatch(fetchChangeSerialOptionsAction(selectedAccount, product, serialForUser));
          resolve(result);
        })
          .then((result: any) => {
            setMigrateOptions(result);
            onMigrationOptionsCalculated(result);
            if (result.length < 1) {
              setMigrateToolTip("No valid options to change to.");
            }
          })
          .catch(() => {
            setMigrateOptions([]);
            onMigrationOptionsCalculated([]);
            setMigrateToolTip("No valid options to change to.");
          })
          .finally(() => {
            setLoadingMigrateOptions(false);
          });
      }
    } else {
      setMigrateOptions([]);
      onMigrationOptionsCalculated([]);
      if (parentIsNotMsp) {
        setMigrateToolTip(`Parent of account ID ${serialForUser.accountId} does not have MSP type`);
      } else {
        setMigrateToolTip("Serial must be active in order to perform a change.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn, product, productsToDisplay, parentProducts.accountId, serialForUser.serial, selectedAccount, parentIsNotMsp]);

  useEffect(() => {
    setShowChangeServiceButton(displayChangeServicesButton(product, serialForUser, mspAccountLoggedIn));
  }, [mspAccountLoggedIn, product, serialForUser]);

  useEffect(() => {
    setShowRemoveSerialButton(displayRemoveSerialButton(isPending, serialForUser, mspAccountLoggedIn));
  }, [mspAccountLoggedIn, serialForUser, isPending]);

  const EditSerialActionButton = () => {
    let buttonLabel;
    let event;
    let datatestid;
    if (isSubpartnerAssigned) {
      if (showUnassignButton) {
        buttonLabel = "UNASSIGN";
        event = onUnassign;
        datatestid = "unassignButton";
      } else {
        return null;
      }
    } else if (isActivationFailed) {
      buttonLabel = "RETRY ACTIVATION";
      event = onRetryActivation;
      datatestid = "retryActivationButton";
    } else {
      return null;
    }
    let isDisabled = isActionInProgress || isPending || parentIsNotMsp || isLoadingParent;
    return (
      <Grid item>
        <div className={isActionInProgress || isPending || parentIsNotMsp || isLoadingParent ? "cursorNotAllowed" : ""}>
          <Tooltip title={getTooltipForSerialActionButton(isPending, serialForUser, isBBS, parentIsNotMsp, false)}>
            <span>
              <Button data-testid={datatestid} className={setNoPoinerEvents(isDisabled)} color="secondary" variant="contained" size="large" onClick={event} disabled={isDisabled}>
                {buttonLabel}
              </Button>
            </span>
          </Tooltip>
        </div>
      </Grid>
    );
  };

  useEffect(() => {
    if (isBBS) {
      setRemoveSerialButtonLabel("DEACTIVATE SERIAL");
    } else {
      setRemoveSerialButtonLabel("REMOVE SERIAL");
    }
  }, [isBBS]);

  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} disableEscapeKeyDown={isActionInProgress} className="showEditSerialDialog" data-testid="showSerialsEditDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)}>
        <div className={"serialsEditModal"}>
          <DialogTitle data-testid="showSerialsEditDialogTitle" id="alert-dialog-title">
            {"EDIT " + product.subname}
          </DialogTitle>
          <DialogContent style={{ padding: "8px 10px 8px 24px" }}>
            <Grid container direction="column" xs={12} spacing={2} style={{ overflowY: "auto" }} justifyContent={"center"}>
              <Grid item xs={12}>
                {displayLink && (
                  <BackTo
                    text={"MSP Account Products & Services"}
                    callback={(e: any) => {
                      isActionInProgress ? e.preventDefault() : handleBackToMSPAccounts(e);
                    }}
                    testId="goBackToMspLink"
                    disable={isActionInProgress}
                  />
                )}
              </Grid>
              <Grid container direction="row" item xs={12}>
                <Grid item xs={productIsBBS(product) ? 6 : 5}>
                  <Grid item>
                    <Typography data-testid="serialsEditModalSerialLabel" variant="button">
                      SERIAL
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography data-testid="serialsEditModalSerialValue" variant="body2" style={{ fontWeight: "bold" }}>
                      {serialForUser.serial.length > 0 ? serialForUser.serial : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                {!productIsBBS(product) && !product.type.includes(ProductFamily.SECUREEDGE_SERVICES) && (
                  <Grid item xs={4}>
                    <Grid item container style={{ justifyContent: "center", display: "grid" }}>
                      <Grid item container>
                        <Typography data-testid="serialsEditModalUserLabel" variant="button">
                          USERS
                        </Typography>
                      </Grid>
                      <Grid item container>
                        <Typography data-testid="serialsEditModalUserValue" variant="body2" style={{ fontWeight: "bold" }}>
                          {serialForUser.activatedSeats}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {product.type.includes(ProductFamily.SECUREEDGE_SERVICES) && !isSecureEdgeServiceSku(product.sku) && (
                  <Grid item xs={4}>
                    <Grid item container style={{ justifyContent: "center", display: "grid" }}>
                      <Grid item container>
                        <Typography data-testid="serialsEditModalUserLabel" variant="button">
                          USERS
                        </Typography>
                      </Grid>
                      <Grid item container>
                        <Typography data-testid="serialsEditModalUserValue" variant="body2" style={{ fontWeight: "bold" }}>
                          {serialForUser.activatedSeats}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {product.type.includes(ProductFamily.SECUREEDGE_SERVICES) && isSecureEdgeServiceSku(product.sku) && (
                  <Grid item xs={4}>
                    <Grid item container style={{ justifyContent: "center", display: "grid" }}>
                      <Grid item container>
                        <Typography data-testid="serialsEditModalUserLabel" variant="button">
                          SIZE
                        </Typography>
                      </Grid>
                      <Grid item container>
                        <Typography data-testid="serialsEditModalUserValue" variant="body2" style={{ fontWeight: "bold" }}>
                          <BandwidthCell item={serialForUser.activatedSeats} noWrap={1} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item container className={getEditSerialsModalGridClassName(product)} xs={productIsBBS(product) ? 6 : 3}>
                  <Grid item container className={getEditSerialsModalStatusClassName(product)} style={{ display: "grid" }}>
                    <Grid item container>
                      <Typography data-testid="serialsEditModalStatusLabel" variant="button">
                        STATUS
                      </Typography>
                    </Grid>
                    <Grid item container>
                      <div data-testid="serialsEditModalStatusValue">
                        <SerialStatusIcon status={serialForUser.status} isBBS={productIsBBS(product)}></SerialStatusIcon>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {isLoadingParent && <CircularProgress data-testid="isLoadingParentForSerialAccount" style={{ marginRight: "15px" }}></CircularProgress>}
            {showChangeServiceButton && (
              <div className={migrateOptions.length < 1 || loadingParentMspProducts || loadingProducts ? "cursorNotAllowed" : ""}>
                <Tooltip key={loadingParentMspProducts.toString() || loadingProducts.toString()} title={migrateToolTip} disableHoverListener={migrateOptions.length > 0}>
                  <span>
                    <Button data-testid="migrateServicesButton" className={setNoPoinerEvents(migrateOptions.length < 1 || loadingParentMspProducts || loadingProducts)} color="secondary" variant="contained" size="large" onClick={onManageService} isLoading={loadingMigrateOptions || loadingParentMspProducts || loadingProducts} disabled={migrateOptions.length < 1 || loadingParentMspProducts || loadingProducts}>
                      {"CHANGE SERVICE"}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            )}
            {showRemoveSerialButton && (
              <Grid item>
                <div className={isLoadingParent ? "cursorNotAllowed" : ""}>
                  <Tooltip title={getTooltipForSerialActionButton(isPending, serialForUser, isBBS, parentIsNotMsp, isSecureEdgeProducts)}>
                    <Button data-testid={"deactivateButton"} color="secondary" variant="contained" size="large" onClick={onDeactivate} isLoading={false} disabled={isActionInProgress || parentIsNotMsp || isLoadingParent || isSecureEdgeProducts}>
                      {removeSerialButtonLabel}
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            )}
            {userHasRole(UserRole.Admin, mspAccountLoggedIn) && EditSerialActionButton()}
            <div className={isLoadingParent ? "cursorNotAllowed" : ""}>
              <Button data-testid="closeShowEditSerialsButton" className={setNoPoinerEvents(isLoadingParent)} size="large" onClick={onCancel} disabled={isLoadingParent}>
                CLOSE
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ShowEditSerialsDialog;

function getTooltipForSerialActionButton(isPending: boolean, serialForUser: ISerial, isBBS: boolean, parentIsNotMsp: boolean, isSecureEdgeProducts: boolean): string {
  if (isSecureEdgeProducts) {
    return `Functionality coming soon. Cannot remove serial from here. Please delete usage from SecureEdge portal.`;
  }
  if (parentIsNotMsp) {
    return `Parent of account ID ${serialForUser.accountId} does not have MSP type`;
  }
  return isPending ? getSerialStatusIconTooltip(serialForUser.status, isBBS) : "";
}

export function getMigrateOptions(mspAccountLoggedIn: IAccount, productToMigrate: IProduct, customerProducts: IProductFamily[], parentProducts: IProductFamily[], parentAccount?: IAccount): IProduct[] {
  const bundles = new NewProductBundles();
  const isCurrentNewBundle = bundles.isNewBundle(productToMigrate?.sku);
  if (!userHasRole(UserRole.Admin, mspAccountLoggedIn)) {
    return [];
  }
  const filteredParentProducts = parentProducts.filter((x: IProductFamily) => x.productType === productToMigrate.type);
  if (filteredParentProducts.length < 1) {
    return [];
  }
  const parentReminingProducts: IProductFamily[] = removeProduct(filteredParentProducts, productToMigrate);
  if (parentReminingProducts.length < 1) {
    return [];
  }
  const filteredCustomerProducts = customerProducts.filter((x: IProductFamily) => x.productType === productToMigrate.type);
  const customerRemainingOptions: IProductFamily[] = removeProduct(filteredCustomerProducts, productToMigrate);

  let availableProducts = getProductsAvailableToActivate(parentReminingProducts, customerRemainingOptions, parentAccount);
  return isCurrentNewBundle ? availableProducts.filter(product => bundles.isNewBundle(product.sku)) : availableProducts;
}

function removeProduct(products: IProductFamily[], productToRemove: IProduct): IProductFamily[] {
  let reminingProducts: IProductFamily[] = [];
  products.forEach((x: IProductFamily) => {
    let prods: IProduct[] = [];
    x.products.forEach((p: IProduct) => {
      if (p.id !== productToRemove.id && parseInt(p.provisionable ?? "") === 1) {
        prods.push(p);
      }
    });
    reminingProducts.push({ ...x, products: prods });
  });
  return reminingProducts;
}
