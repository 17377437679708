import React, { useEffect, useState } from "react";
import IAccount from "../../../../models/IAccount";
import { Grid, CheckboxLabel, Typography, Switch, SwitchLabel } from "@cuda-networks/bds-core";
import BackTo from "../../../BackTo";
import MspType from "../../../../models/MspType";
import IUser from "../../../../models/IUser";
import { getCustomersAction } from "../../../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/store";
import { addChildrenAccountsToSelectionAction, expandMspAction, navigateToAccountAccessItemAction, setAccountAccessPageNumberAction, setAccountAccessPageSizeAction, setAccountAccessToDisplayAction, setIsViewingMspAction, updateAccountsSelectionAction, updateAccountsSelectionForFutureAccountsAction } from "../../../../actions/loginAccountAccessActions";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import { CheckedState } from "../../../../models/CheckedState";
import AccountsTableWithoutState from "../../../Accounts/AccountsTableBase";
import CheckBoxWithIndeterminateState from "../../../CheckBoxWithIndeterminateState";

interface IAccountAccessStepProps {
  isEdit: boolean;
  user: IUser | undefined;
  selectedAccount: IAccount | undefined;
  isEditingLoggedInUser: boolean;
}

const AccountAccessStep: React.FC<IAccountAccessStepProps> = ({ isEdit, selectedAccount, isEditingLoggedInUser }) => {
  const dispatch = useDispatch();
  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const accountAccessSelection = useSelector((state: IAppState) => state.loginAccountAccessState.accountAccessSelection);
  const globalSelectAll = useSelector((state: IAppState) => state.loginAccountAccessState.selectAll);
  const globalIncludeFutureAccounts = useSelector((state: IAppState) => state.loginAccountAccessState.includeFutureAccounts);
  const expandedAccountAccessMsp = useSelector((state: IAppState) => state.loginAccountAccessState.expandedAccountAccessMsp);
  const isViewingMsp = useSelector((state: IAppState) => state.loginAccountAccessState.isViewingMsp);
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const itemsToDisplay = useSelector((state: IAppState) => state.loginAccountAccessState.accountsAccessItemsToDisplay);
  const accountsAccessPageSize = useSelector((state: IAppState) => state.loginAccountAccessState.accountsAccessPageSize);
  const accountsAccessPageNumber = useSelector((state: IAppState) => state.loginAccountAccessState.accountsAccessPageNumber);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const [allSelected, setAllSelected] = useState(globalSelectAll);
  const [includeFutureAccounts, setIncludeFutureAccounts] = useState(globalIncludeFutureAccounts);
  const [includeFutureAccountsDisabled, setIncludeFutureAccountsDisabled] = useState(false);
  const [title, setTitle] = useState("");
  const [showBackToLink, setShowBackToLink] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IAccount | undefined>();

  useEffect(() => {
    setShowBackToLink(showAccountAccessBackToLink(isViewingMsp, selectedAccount, hasSubpartners));
    setTitle(showAccountAccessTitle(isViewingMsp, selectedAccount, expandedAccountAccessMsp));
  }, [isViewingMsp, selectedAccount, expandedAccountAccessMsp, hasSubpartners]);

  useEffect(() => {
    if (allSelected !== CheckedState.Checked) {
      setIncludeFutureAccounts(false);
      setIncludeFutureAccountsDisabled(true);
    } else {
      if (allSelected === CheckedState.Checked) {
        setIncludeFutureAccountsDisabled(false);
      }
    }
  }, [allSelected]);

  useEffect(() => {
    if (expandedAccountAccessMsp) {
      setIncludeFutureAccounts(expandedAccountAccessMsp.includeFutureAccounts === true);
      setAllSelected(expandedAccountAccessMsp.checkedState ? expandedAccountAccessMsp.checkedState : CheckedState.Unchecked);
    } else {
      setIncludeFutureAccounts(globalIncludeFutureAccounts);
      setAllSelected(globalSelectAll);
      if (globalSelectAll !== CheckedState.Checked) {
        setIncludeFutureAccounts(false);
        setIncludeFutureAccountsDisabled(true);
      } else {
        if (globalSelectAll === CheckedState.Checked) {
          setIncludeFutureAccountsDisabled(false);
        }
      }
    }
  }, [expandedAccountAccessMsp, globalIncludeFutureAccounts, globalSelectAll]);

  const resetItems = (items: IAccount[]) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(setAccountAccessToDisplayAction(items));
      resolve(success);
    });

  const handleBackToAccounts = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSelectedItem(undefined);
    setIncludeFutureAccounts(globalIncludeFutureAccounts);
    setAllSelected(globalSelectAll);
    dispatch(expandMspAction(undefined));
    dispatch(setIsViewingMspAction(true));
    resetItems(accountAccessSelection).then(() => {
      if (expandedAccountAccessMsp?.id) {
        dispatch(navigateToAccountAccessItemAction(expandedAccountAccessMsp?.id));
      }
    });
  };

  const getSubpartnerCustomers = (account: IAccount, updateItemsToDisplay: boolean) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(getCustomersAction(account.id, updateItemsToDisplay));
      resolve(success);
    });

  const addChildrenAccountsToSelection = (isEdit: boolean, account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(addChildrenAccountsToSelectionAction(isEdit, account));
      resolve(success);
    });

  const onItemSelected = (item: IAccount): void => {
    if (selectedItem === undefined || (selectedItem !== undefined && selectedItem.id !== item.id)) {
      setSelectedItem(item);
      if (isViewingMsp) {
        if (item.type === MspType.Partner) {
          dispatch(expandMspAction(item.id));
        } else if (item.type === MspType.Subpartner) {
          if (item.accounts) {
            dispatch(expandMspAction(item.id));
            dispatch(setAccountAccessPageNumberAction(1));
          } else {
            getSubpartnerCustomers(item, false).then(response => {
              if (response) {
                addChildrenAccountsToSelection(isEdit, { ...response, checkedState: item.checkedState, includeFutureAccounts: item.includeFutureAccounts }).then(() => {
                  dispatch(expandMspAction(response.id));
                  dispatch(setAccountAccessPageNumberAction(1));
                });
              }
            });
          }
        }
      }
    }
  };

  const onPageNumberChange = (value: number): void => {
    dispatch(setAccountAccessPageNumberAction(value));
  };

  const onPageSizeChange = (value: number): void => {
    dispatch(setAccountAccessPageSizeAction(value));
  };

  const onItemChecked = (item: IAccount, checked: boolean): void => {
    dispatch(updateAccountsSelectionAction(item, checked));
  };

  function onHandleSelectAllChange(checked: boolean) {
    dispatch(updateAccountsSelectionAction(expandedAccountAccessMsp, checked));
  }

  function onChangeAddFutureAccounts(checked: boolean) {
    dispatch(updateAccountsSelectionForFutureAccountsAction(checked));
  }

  return (
    <div className="AddEditLoginAccountAccessStep" data-testid="addEditLoginAccountAccessStep">
      <Grid container item xs={12}>
        <Grid container spacing={1} style={{ paddingBottom: "10px" }}>
          <Grid container item xs={12}>
            <Tooltip title={title}>
              <Typography data-testid={"accountAccesStepTitle"} variant="h4" noWrap>
                {title}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid container item xs={12}>
            {isViewingMsp && (
              <Typography variant="caption" data-testid={"accountAccesStepSubTitle"}>
                {"Selecting a partner includes all of its associated accounts."}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container>
          <div style={{ display: "inline-block", height: "29px", width: "100%" }}>{showBackToLink ? <BackTo text={"partners"} callback={handleBackToAccounts} testId="accountAccessBackToLink" /> : <span style={{ visibility: "hidden", display: "inline-block", height: "29px" }}></span>}</div>
        </Grid>
      </Grid>

      {itemsToDisplay.length === 0 ? (
        <Grid container item xs={12} style={{ justifyContent: "center" }}>
          <Grid item xs={12} style={{ display: "contents" }}>
            <SwitchLabel label={"Automatically Include Future Accounts"} size="small" control={<Switch data-testid={"includeFutureAccounts"} disabled={false} checked={includeFutureAccounts} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onChangeAddFutureAccounts(ev.target.checked)} />} style={{ marginLeft: "15px" }} />
            <CoreIcons.Update className={"centerVertically AccountAccessUpdateIcons"} />
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: "10px", justifyContent: "center" }}>
            <Typography data-testid={"accountAccesNoAccounts"}>{"This partner does not currently have any accounts associated with it"}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={12}>
          <Grid item className="AccountAccessSelectAllSpacer"></Grid>
          <Grid item xs={4}>
            <CheckboxLabel data-testid={"accountAccessStepSelectAll"} label="Select All" size="small" control={<CheckBoxWithIndeterminateState disabled={false} onCheck={onHandleSelectAllChange} checkedState={allSelected} data-testid="selectAllCheckbox" />} />
          </Grid>
          <Grid item xs={7} style={{ display: "contents" }}>
            <SwitchLabel label={"Automatically Include Future Accounts"} size="small" control={<Switch data-testid={"includeFutureAccounts"} disabled={includeFutureAccountsDisabled} checked={includeFutureAccounts} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onChangeAddFutureAccounts(ev.target.checked)} />} style={{ marginLeft: "15px" }} />
            <CoreIcons.Update className={"centerVertically AccountAccessUpdateIcons"} />
          </Grid>
          <Grid container item xs={12} data-testid="loginAccountsAccessTable">
            {selectedAccount && <AccountsTableWithoutState showExpandIcon={isViewingMsp} pageSize={accountsAccessPageSize} pageNumber={accountsAccessPageNumber} items={itemsToDisplay} selectedAccount={selectedItem} hasSubpartners={isViewingMsp} viewSearchResults={false} loadingAccountId={loadingAccountId} onItemSelected={onItemSelected} onPageSizeChange={onPageSizeChange} onPageNumberChange={onPageNumberChange} showCheckboxes={true} checkboxesDisabled={isEditingLoggedInUser} isBaLoggedIn={isBaLoggedIn} onItemChecked={onItemChecked} showInfoIcon={true} defaultPagination={[5, 10, 15]} />}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AccountAccessStep;

function showAccountAccessTitle(isViewingMsp: boolean, selectedAccount: IAccount | undefined, expandedAccountAccessMsp: IAccount | undefined): string {
  if (expandedAccountAccessMsp) {
    return expandedAccountAccessMsp.name;
  } else {
    if (selectedAccount?.type === MspType.Partner && isViewingMsp) {
      return "Partners";
    } else {
      if (selectedAccount) {
        return selectedAccount.name;
      }
    }
  }
  return "";
}

function showAccountAccessBackToLink(isViewingMsp: boolean, selectedAccount: IAccount | undefined, hasSubpartners: boolean): boolean {
  return selectedAccount?.type === MspType.Partner && hasSubpartners && !isViewingMsp;
}
