import { Dispatch } from "redux";
import IAccount from "../../models/IAccount";
import { IAppState } from "../../store/store";
import { AuditUserFilterByOption } from "../../models/Products/IAuditUserDisplayState";
import { AuditUsersActionsTypes } from "../auditUsersActions";
import { AccountActionTypes, filterAccounts } from "../accountActions";
import MspType from "../../models/MspType";
import { GeneralActionTypes } from "../generalActions";
import { NoMspTypeError, ParentNotFoundError } from "../../models/constants";
import { resetCw } from "../../components/Accounts/AccountsTable";
import { getDisplayItemsForMsp, getMspAccountsToDisplay, goToAccount, setAccountsPageNumberForAccountId } from "../../businessLogic/accounts";
import { areFiltersActive, findAccountById } from "../../Utilities/accountsHelper";
import { cancelFilterAccountsActionToken, cancelLoadPartnerWithChildrenActionTokenAndCreateNew, cancelLoadSubPartnerCustomerTokenAndCreateNew } from "../cancelAction";
import { computeProdSkuFilterOptions, getOrders } from "../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import { getOrdersForAccountsFiltering } from "../productActions";
import IOrderSlim from "../../models/Products/IOrderSlim";
import { IAccountFilters } from "../../models/IAccountFilters";
import ProductFamily from "../../models/Products/ProductFamily";
import { dynamicSort } from "../../utility";
import produce from "immer";
import mspService from "../../service/mspService";
import { handleError } from "../actionsErrorHandler";
import { findItemWithClosestParentIdInState } from "../../businessLogic/components/Accounts/AccountsTable";

export async function onSelectAccountItemDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>, item: IAccount) {
  const { mspAccountLoggedIn, viewSearchResults, isBaLoggedIn, hasSubpartners } = getState().generalState;
  const { selectedAccount } = getState().accountState;

  dispatch({ type: AuditUsersActionsTypes.SET_SEARCHED_AUDIT_USER_EMAIL, searchedAuditUserEmail: "" });
  dispatch({ type: AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE, auditUserStateBeforeSearch: undefined });
  dispatch({ type: AuditUsersActionsTypes.SET_FILTER_BY_OPTION, filterByOptionId: AuditUserFilterByOption.BILLABLE_USER });

  if (!viewSearchResults) {
    if (isBaLoggedIn) {
      dispatch({
        type: AccountActionTypes.SET_SELECTED_ACCOUNT,
        selectedAccount: item,
        showEmptyPanel: item !== undefined ? false : true,
      });

      if (item?.type === MspType.Partner) {
        dispatch({ type: AccountActionTypes.SET_EXPANDED_PARTNER, expandedPartner: item });
      }
    } else {
      if (item?.type === MspType.Subpartner) {
        if (selectedAccount?.closestParentId === item.id || mspAccountLoggedIn.type === MspType.Subpartner) {
          setSubpartnerAsSelectedAccount(mspAccountLoggedIn.type === MspType.Subpartner, item, dispatch);
        } else {
          const response = await getCustomersDispatchAction(getState, dispatch, item.id, true);
          if (response) {
            setSubpartnerAsSelectedAccount(false, item, dispatch);
          }
        }
      } else if (item?.type === MspType.Partner) {
        cancelLoadSubpartnerCustomersDispatchAction(getState, dispatch);
        if (hasSubpartners) {
          dispatch({ type: GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS, viewMspAccounts: false });
        }
        setDisplayCustomersByAccountIdDispatchAction(getState, dispatch, item);
        dispatch({ type: AccountActionTypes.SET_EXPANDED_PARTNER, expandedPartner: item });
        dispatch({
          type: AccountActionTypes.SET_SELECTED_ACCOUNT,
          selectedAccount: item,
          showEmptyPanel: item !== undefined ? false : true,
        });
      } else {
        if (hasSubpartners) {
          dispatch({ type: GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS, viewMspAccounts: false });
        }
        dispatch({
          type: AccountActionTypes.SET_SELECTED_ACCOUNT,
          selectedAccount: item,
          showEmptyPanel: item !== undefined ? false : true,
        });
      }
    }
  } else {
    if (item === undefined) {
      dispatch({
        type: AccountActionTypes.SET_SELECTED_ACCOUNT,
        selectedAccount: undefined,
        showEmptyPanel: true,
      });
    } else {
      if (item.closestParentId !== 0 && item.closestParentId !== undefined) {
        dispatch({
          type: AccountActionTypes.SET_SELECTED_ACCOUNT,
          selectedAccount: item,
          showEmptyPanel: false,
        });
      } else {
        const { accountsNames } = getState().accountState;
        const { itemsToDisplay } = getState().accountState;
        const { mspAccounts } = getState().accountState;
        const { foundAccount, shouldGetParent } = findItemWithClosestParentIdInState(item, mspAccountLoggedIn, accountsNames, isBaLoggedIn, itemsToDisplay, mspAccounts);
        if (foundAccount) {
          let updatedItem = { ...item };
          if (item.type === MspType.Customer) {
            if (updatedItem.m365AuthLinked === undefined) {
              updatedItem.m365AuthLinked = foundAccount.m365AuthLinked;
              updatedItem.m365AuthUpdateTime = foundAccount.m365AuthUpdateTime;
            }
          }

          if (shouldGetParent && (item.closestParentId === 0 || item.closestParentId === undefined)) {
            const response = await loadParentAccountWithChildrenDispatchAction(getState, dispatch, foundAccount.id, false);
            if (response) {
              const closestParentId = response.closestParentId;
              if (closestParentId) {
                dispatch({
                  type: AccountActionTypes.SET_SELECTED_ACCOUNT,
                  selectedAccount: { ...updatedItem, closestParentId: closestParentId },
                  showEmptyPanel: false,
                });
              } else {
                if (response.reason === NoMspTypeError) {
                  dispatch({ type: GeneralActionTypes.ERROR, errorMessage: "Parent of account ID " + item.id + " has no MSP type" });
                }
              }
            }
          } else {
            dispatch({
              type: AccountActionTypes.SET_SELECTED_ACCOUNT,
              selectedAccount: updatedItem,
              showEmptyPanel: false,
            });
          }
        } else {
          console.error("Account id " + item.id + " not found in children state");
        }
      }
    }
  }

  if (isBaLoggedIn) {
    dispatch({ type: AccountActionTypes.SET_SELECTED_ACCOUNT_TO_FILTER_PRODUCTS_FOR_BA, selectedAccountToFilterProductsForBA: undefined });
  } else if (item?.type !== MspType.Partner) {
    resetCw(dispatch);
  }
}

function setSubpartnerAsSelectedAccount(setViewMsp: boolean, item: IAccount, dispatch: Dispatch<any>) {
  dispatch({ type: GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS, viewMspAccounts: setViewMsp });
  dispatch({ type: AccountActionTypes.SET_EXPANDED_PARTNER, expandedPartner: item });
  dispatch({
    type: AccountActionTypes.SET_SELECTED_ACCOUNT,
    selectedAccount: item,
    showEmptyPanel: item !== undefined ? false : true,
  });
  dispatch({ type: AccountActionTypes.SET_PAGE_NUMBER, accountsPageNumber: 1 });
}

export async function goBackToViewMspAccountsDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>, prevSelected: IAccount, prevExpanded: IAccount) {
  dispatch({ type: GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS, viewMspAccounts: true });
  dispatch({ type: AccountActionTypes.SET_EXPANDED_PARTNER, expandedPartner: undefined });
  dispatch({
    type: AccountActionTypes.SET_SELECTED_ACCOUNT,
    selectedAccount: undefined,
    showEmptyPanel: true,
  });
  dispatch({ type: AccountActionTypes.SAVE_DISPLAY_STATE, stateBeforeFilterAccounts: undefined });
  const { mspAccounts } = getState().accountState;
  const itemsToDisplay = getMspAccountsToDisplay(mspAccounts);
  dispatch({
    type: AccountActionTypes.SET_DISPLAY_ITEMS,
    itemsToDisplay: itemsToDisplay,
  });
  navigateBackToAccounts(getState, dispatch, prevSelected, prevExpanded);
}

function navigateBackToAccounts(getState: () => IAppState, dispatch: Dispatch<any>, prevSelected: IAccount, prevExpanded: IAccount) {
  const { filters } = getState().accountState;
  const { mspAccountLoggedIn } = getState().generalState;
  if (areFiltersActive(filters)) {
    navigateBackToFilteredAccounts(getState, dispatch, mspAccountLoggedIn, filters);
  } else {
    navigateBackToPreviousAccounts(getState, dispatch, prevSelected, prevExpanded);
  }
}

function navigateBackToFilteredAccounts(getState: () => IAppState, dispatch: Dispatch<any>, mspAccountLoggedIn: IAccount, filters: IAccountFilters) {
  cancelFilterAccountsActionToken(getState, dispatch);
  dispatch({
    filterChildrenOfAccountId: mspAccountLoggedIn.id,
    directChildrenOnly: false,
    type: AccountActionTypes.SET_FITLER_CHILDREN_OF_ACCOUNT_ID,
  });
  if (filters?.csProdSkus || filters?.bbsProdSkus) {
    updateFiltersWithPartnerSkusAndFilter(getState, dispatch, mspAccountLoggedIn, filters);
  } else {
    filterAccounts(getState, dispatch);
  }
}

function navigateBackToPreviousAccounts(getState: () => IAppState, dispatch: Dispatch<any>, prevSelected: IAccount, prevExpanded: IAccount) {
  if (prevSelected) {
    if (prevSelected.type === MspType.Customer && prevSelected.closestParentId) {
      navigateToAccountDispatchAction(getState, dispatch, prevSelected.closestParentId);
    } else {
      navigateToAccountDispatchAction(getState, dispatch, prevSelected.id);
    }
  } else {
    if (prevExpanded) {
      navigateToAccountDispatchAction(getState, dispatch, prevExpanded.id);
    }
  }
}

function updateFiltersWithPartnerSkusAndFilter(getState: () => IAppState, dispatch: Dispatch<any>, mspAccountLoggedIn: IAccount, filters: IAccountFilters) {
  const { accountsOrders } = getState().productState;
  const currentOrders = getOrders(accountsOrders, mspAccountLoggedIn.id);
  if (currentOrders === undefined) {
    dispatch({ type: AccountActionTypes.SET_LOADING_FILTERED_ACCOUNTS, loadingFilteredAccounts: true });
    getOrdersForAccountsFiltering(getState, dispatch, mspAccountLoggedIn.id).then(result => {
      if (result) {
        filterWithPartnerOrders(getState, dispatch, result, filters);
      }
    });
  } else {
    filterWithPartnerOrders(getState, dispatch, currentOrders, filters);
  }
}

function filterWithPartnerOrders(getState: () => IAppState, dispatch: Dispatch<any>, currentOrders: IOrderSlim[], filters: IAccountFilters) {
  let newFilters: IAccountFilters = { ...filters };
  if (filters?.csProdSkus !== undefined && filters.csProdSkus.length > 0) {
    const csSkus: string | undefined = computeProdSkuFilterOptions(currentOrders, ProductFamily.CONTENT_SHIELD);
    newFilters = { ...newFilters, csProdSkus: csSkus };
  }
  if (filters?.bbsProdSkus !== undefined && filters.bbsProdSkus.length > 0) {
    const bbsSkus: string | undefined = computeProdSkuFilterOptions(currentOrders, ProductFamily.BACKUP_APPLIANCES);
    newFilters = { ...newFilters, bbsProdSkus: bbsSkus };
  }
  dispatch({
    filters: newFilters,
    type: AccountActionTypes.SET_ACCOUNT_FILTERS,
  });
  filterAccounts(getState, dispatch);
}

export async function prepareAccountsListDispatchAction(getState: () => IAppState, accId: string) {
  const accountId: number = Number(accId);
  const { apiUrl, mspAccountLoggedIn, isBaLoggedIn } = getState().generalState;
  const { mspAccounts, accountsPageSize, accountsNames, itemsToDisplay } = getState().accountState;

  let result;
  let foundAccount;
  if (isBaLoggedIn) {
    foundAccount = findAccountById(itemsToDisplay, accountsNames, accountId);
    if (foundAccount !== undefined && ((foundAccount.closestParentId !== undefined && foundAccount.closestParentId !== 0) || mspAccountLoggedIn.id === accountId)) {
      result = goToAccount(itemsToDisplay, accountsPageSize, foundAccount, true);
    }
  } else {
    foundAccount = findAccountById(mspAccounts, accountsNames, accountId);
    if (foundAccount !== undefined && ((foundAccount.closestParentId !== undefined && foundAccount.closestParentId !== 0) || mspAccountLoggedIn.id === accountId)) {
      result = goToAccount(mspAccounts, accountsPageSize, foundAccount);
    } else {
      const parent = await mspService.loadParentAccount(apiUrl, accountId);
      const closestParentId = parent[0].id;
      const mspIndex = mspAccounts.findIndex((x: IAccount) => x.id.toString() === closestParentId.toString());
      if (mspAccounts[mspIndex].accounts !== undefined) {
        const account1 = mspAccounts[mspIndex].accounts?.find(x => x.id === accountId);
        if (account1) {
          result = goToAccount(mspAccounts, accountsPageSize, account1);
        }
      } else {
        const children = await mspService.loadAccountChildren(apiUrl, closestParentId, undefined);
        const customers = children.accounts.filter((x: IAccount) => x.type === MspType.Customer);
        const updatedResults = customers.map((x: IAccount) => ({ ...x, closestParentId: closestParentId }));
        updatedResults.sort(dynamicSort("name"));

        const nextStateUsers = produce(mspAccounts, draft => {
          draft[mspIndex].accounts = [...updatedResults];
        });
        const account1 = nextStateUsers[mspIndex].accounts?.find(x => x.id === accountId);
        if (account1) {
          result = goToAccount(nextStateUsers, accountsPageSize, account1);
        }
      }
    }
  }
  return result;
}

export async function getCustomersDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>, accountId: number, updateItemsToDisplay: boolean) {
  try {
    const { apiUrl } = getState().generalState;
    const { mspAccounts } = getState().accountState;
    const mspIndex = mspAccounts.findIndex(x => x.id === accountId);
    if (mspIndex < 0) {
      return undefined;
    }

    const newCancelTokenSource = cancelLoadSubPartnerCustomerTokenAndCreateNew(getState, dispatch); //?

    dispatch({
      type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
      loadingAccountId: accountId,
    });
    let nextStateUsers: IAccount[] = [];
    if (mspAccounts[mspIndex].accounts === undefined) {
      const result = await mspService.loadAccountChildren(apiUrl, accountId, newCancelTokenSource.token);
      const customers = result.accounts.filter((x: IAccount) => x.type === MspType.Customer);
      const updatedResults = customers.map((x: IAccount) => ({ ...x, closestParentId: accountId }));
      updatedResults.sort(dynamicSort("name"));
      nextStateUsers = produce(mspAccounts, draft => {
        draft[mspIndex].accounts = [...updatedResults];
      });
    } else {
      nextStateUsers = mspAccounts;
    }
    const { itemsToDisplay } = getState().accountState;
    const children = nextStateUsers[mspIndex].accounts;
    const itemsToDisplay1 = children !== undefined ? [nextStateUsers[mspIndex], ...children] : [nextStateUsers[mspIndex]];
    dispatch({
      type: AccountActionTypes.SET_MSP_ACCOUNTS,
      itemsToDisplay: updateItemsToDisplay ? itemsToDisplay1 : itemsToDisplay,
      mspAccounts: nextStateUsers,
    });
    dispatch({
      type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
      loadingAccountId: undefined,
    });
    return nextStateUsers[mspIndex];
  } catch (err) {
    handleError(err, dispatch, () => {
      dispatch({
        type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
        loadingAccountId: undefined,
      });
    });
    return undefined;
  }
}

export function cancelLoadSubpartnerCustomersDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>) {
  const cancelTokenSource = getState().accountState.loadSubpartnerCustomersCancellationTokenSource;
  if (cancelTokenSource) {
    cancelTokenSource?.cancel("Operation canceled.");
  }
  dispatch({
    loadingAccountId: undefined,
    type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
  });
  dispatch({
    loadSubpartnerCustomersCancellationTokenSource: undefined,
    type: AccountActionTypes.SET_CANCEL_LOAD_SUBPARTNER_CUSTOMERS_TOKEN,
  });
}

export async function loadParentAccountWithChildrenDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>, accountId: number, withChildren: boolean) {
  try {
    const { apiUrl } = getState().generalState;
    const { mspAccounts } = getState().accountState;
    const { itemsToDisplay } = getState().accountState;
    const newCancelTokenSource = cancelLoadPartnerWithChildrenActionTokenAndCreateNew(getState, dispatch);

    dispatch({
      type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
      loadingAccountId: accountId,
    });

    const parent = await mspService.loadParentAccount(apiUrl, accountId, newCancelTokenSource.token);
    if (parent && parent[0]) {
      const closestParentId = parent[0].id;
      const mspIndex = mspAccounts.findIndex((x: IAccount) => x.id.toString() === closestParentId.toString());
      if (mspIndex > -1) {
        let nextStateCustomers: IAccount[] = [];
        if (withChildren) {
          const parentChildren = await mspService.loadAccountChildren(apiUrl, closestParentId, newCancelTokenSource.token);
          const customers = parentChildren.accounts.filter((x: IAccount) => x.type === MspType.Customer);
          const accounts = customers.map((x: IAccount) => ({ ...x, closestParentId }));
          accounts.sort(dynamicSort("name"));

          nextStateCustomers = produce(mspAccounts, (draft: IAccount[]) => {
            draft[mspIndex].accounts = accounts;
          });
          dispatch({
            type: AccountActionTypes.SET_MSP_ACCOUNTS,
            itemsToDisplay: itemsToDisplay,
            mspAccounts: nextStateCustomers,
          });
          dispatch({
            type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
            loadingAccountId: undefined,
          });
          const accIndex = accounts.findIndex((x: IAccount) => x.id.toString() === accountId.toString());
          if (accIndex > -1) {
            return { account: accounts[accIndex] };
          } else {
            return undefined;
          }
        } else {
          dispatch({
            type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
            loadingAccountId: undefined,
          });
          return { closestParentId: closestParentId };
        }
      } else {
        dispatch({
          type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
          loadingAccountId: undefined,
        });
        return { closestParentId: undefined, reason: NoMspTypeError };
      }
    }
    return { closestParentId: undefined, reason: ParentNotFoundError };
  } catch (err) {
    handleError(
      err,
      dispatch,
      () => {
        dispatch({
          type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
          loadingAccountId: undefined,
        });
      },
      () => {
        dispatch({
          type: AccountActionTypes.SET_LOADING_ACCOUNT_INFO,
          loadingAccountId: undefined,
        });
      },
    );
    return undefined;
  }
}

export function navigateToAccountDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>, accountId: number) {
  const { itemsToDisplay } = getState().accountState;
  const { accountsPageSize } = getState().accountState;
  try {
    const accountsPageNumber = setAccountsPageNumberForAccountId(itemsToDisplay, accountsPageSize, accountId);
    dispatch({ type: AccountActionTypes.SET_PAGE_NUMBER, accountsPageNumber });
  } catch (error) {
    console.log(error);
  }
}

export function setDisplayCustomersByAccountIdDispatchAction(getState: () => IAppState, dispatch: Dispatch<any>, account: IAccount) {
  const { mspAccounts } = getState().accountState;
  const itemsToDisplay = getDisplayItemsForMsp(mspAccounts, account);
  dispatch({
    type: AccountActionTypes.SET_DISPLAY_ITEMS,
    itemsToDisplay: itemsToDisplay,
  });
}
